/* eslint-disable */
import {
  Avatar,
  Button,
  Center,
  Flex,
  Link,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
import { FaBuilding } from 'react-icons/fa'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'
import { getUserAuthenticationRole } from 'hooks/authService'
import axios from 'api/axios'
import pipelineStatus from 'components/setPipelineStatusName'
const userRole = getUserAuthenticationRole()

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import React, { useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { SearchBar } from 'components/navbar/searchBar/SearchBar'
import { getUserAccountId } from 'hooks/authService'
import authHeader from 'hooks/authHeader'

const columnsData = [
  { Header: 'Nome', accessor: 'first_name' },
  { Header: 'Perfil', accessor: 'role' },
  { Header: 'Status', accessor: 'status' }
]

export default function UsersTable() {
  const [status, setStatus] = useState('')
  const [tableData, setTableData] = useState([''])

  const accountId = getUserAccountId()

  useEffect(() => {
    getAccountUsers()
  }, [])

  async function getAccountUsers() {
    if (!accountId) return

    try {
      const { data } = await axios.get('/users', {
        headers: authHeader(),
        query: {
          user_data: {
            $contains: {
              main_account_id: accountId
            }
          }
        }
      })

      console.log('data:::::', data.data)
      setTableData(data)
    } catch (error) {
      console.error('Erro ao buscar usuarios:', error)
    }
  }

  const handleChangeStatus = (event) => {
    setStatus(event.value)
  }

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 50

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')

  console.log('headerGroups', headerGroups)

  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Usuários
        </Text>
        <Flex alignItems="center">
          <Text mr="10px">Filtros</Text>
          <Select
            value={status}
            onChange={handleChangeStatus}
            variant="auth"
            fontSize="sm"
            placeholder="Status"
            fontWeight="500"
            size="md"
            borderRadius="16px"
            mr="10px"
          >
            <option value="new">Novo</option>
            <option value="contacted">Contatado</option>
            <option value="no-fit">Sem Fit</option>
            <option value="qualified">Qualificado</option>
            <option value="client">Cliente</option>
          </Select>
          <SearchBar />
        </Flex>
        <Flex gap={5} alignItems="center">
          <Flex direction="columns">
            <Link href="/admin/new-contact" alignItems="center">
              <Icon as={MdPersonAdd} color={iconColor} w="24px" h="24px" />
              <Text px="5px" fontSize="sm">
                Novo
              </Text>
            </Link>
          </Flex>
          <Flex direction="columns">
            <Link href="/admin/import-contacts">
              <Icon as={MdImportExport} color={iconColor} w="24px" h="24px" />
              <Text px="5px" fontSize="sm">
                Importar
              </Text>
            </Link>
          </Flex>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/admin/contact/${row.original.id}`}>
                    <Flex alignItems="center">
                      <Avatar
                        size="md"
                        name={row.original.name}
                        src={`companies/${row.original.logo}`}
                        mr="10px"
                      />
                      <Flex flexDirection={'column'}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {`${row.original.first_name} ${row.original.last_name}`}
                          <span style={{ fontWeight: '400' }}>
                            {`, ${row.original.role && row.original.role}`}
                          </span>
                        </Text>
                      </Flex>
                    </Flex>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.first_name}
                  </Text>
                </Td>
                {userRole === 'main' ||
                  (userRole === 'super-admin' && (
                    <>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                      >
                        <Text color={textColor} fontSize="sm" fontWeight="300">
                          <Link href={`/admin/user/${row.original.id}`}>
                            <span
                              style={{
                                padding: '2px 10px',
                                borderRadius: '50px',
                                background: '#eaeaea',
                                fontSize: '12px',
                                color: '#8f9bba'
                              }}
                            >
                              {row.original.role}
                            </span>
                          </Link>
                        </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                      >
                        <Text color={textColor} fontSize="sm" fontWeight="300">
                          <Link href={`/admin/user/${row.original.id}`}>
                            <span
                              style={{
                                padding: '2px 10px',
                                borderRadius: '50px',
                                background: '#eaeaea',
                                fontSize: '12px',
                                color: '#8f9bba'
                              }}
                            >
                              Editar
                            </span>
                          </Link>
                        </Text>
                      </Td>
                    </>
                  ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
