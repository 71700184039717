import React from 'react'
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Portal,
  Popover,
  PopoverContent,
  PopoverCloseButton,
  PopoverArrow,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import DealForm from '../../formDeal/components/dealForm'
import { FaPlus } from 'react-icons/fa'

export default function NewStepDeal({ onClose, onOpen, isOpen, stepId, pipelineId, stepName, onUpdate }) {
  const focusInicial = React.useRef()
  const firstFieldRef = React.useRef(null)

  return (
    <>
      {/* <Modal isOpen={isOpen} initialFocusRef={focusInicial} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="800px" borderRadius={'20px'}>
          <ModalCloseButton />
          <ModalBody bg={'whiteAlpha.200'} pt="30px" borderRadius={'20px'}>
            <DealForm newDealStep={stepName} pipelineId={pipelineId} stepId={stepId} onUpdate={onUpdate} />
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Flex
            justifyContent={'center'}
            onClick={() => onOpen()}
            cursor="pointer"
            p="5px 15px"
            background="gray.200"
            borderRadius={'50px'}
            mb="15px"
          >
            <FaPlus size={'10px'} color={'gray'} p="5px 10px" />
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            width={'670px'}
            p="20px"
            boxShadow={'2xl'}
            borderRadius={'30px 10px 30px 30px'}
            mr="20px"
            mb="20px"
            zIndex={9999}
          >
            <DealForm
              inicialFocus={firstFieldRef}
              newDealStep={stepName}
              pipelineId={pipelineId}
              stepId={stepId}
              onUpdate={onUpdate}
              onClose={onClose}
            />

            <PopoverArrow />
            <PopoverCloseButton mt="3px" />
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}
