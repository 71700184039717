import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAuthentication } from 'hooks/authService'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  SimpleGrid,
  useColorModeValue,
  Select
} from '@chakra-ui/react'
import Card from 'components/card/Card'
import AtualizarSenha from '../AtualizarSenha'
import { cpf } from 'cpf-cnpj-validator'
import { Icon } from '@chakra-ui/react'
import { NotAllowedIcon } from '@chakra-ui/icons'

export default function Settings() {
  const textColor = useColorModeValue('navy.700', 'white')

  const { user } = getUserAuthentication()

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/users/${user.id}`, {
        headers: authHeader()
      })
      setFormData(data)
      console.log('request.data', data)
      return data
    }

    if (user && user.id) {
      fetchData()
    }
  }, [])

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: ''
  })

  const [isDataExists, setIsDataExists] = useState(false)
  const [sendAlert, setSendAlert] = useState('')

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleCPFChange = (event) => {
    const validCPF = cpf.isValid(event.target.value)
    console.log('validCPF:', validCPF)

    validCPF === true &&
      setFormData((prevFormData) => ({
        ...prevFormData,
        cpf: cpf.format(event.target.value)
      }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (user && user.id) {
      try {
        const response = await axios.patch(`/users/${user.id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error)
      }
    } else {
      if (!isDataExists) {
        try {
          const response = await axios.post('/users', formData)
          console.log('Dados enviados com sucesso!', response.data)
          setSendAlert('success')
        } catch (error) {
          console.error('Ocorreu um erro ao enviar os dados:', error)
          setSendAlert('error')
        }
      }
    }
    console.log(formData)
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        mb="20px"
        columns={2}
        spacing={{ base: '20px', xl: '20px' }}
        style={{ alignItems: 'start' }}
      >
        <Card>
          <Text
            style={{
              color: '#1a202c',
              fontSize: '22px',
              fontWeight: '700',
              lineHeight: '100%'
            }}
            mb={'20px'}
          >
            Seus dados cadastrados
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Seu nome
              </FormLabel>
              <Input
                value={formData.first_name}
                name="first_name"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Nome e sobrenome"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Seu email
              </FormLabel>
              <Input
                value={formData.email}
                name="email"
                onChange={handleChange}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Informe seu email"
                mb="24px"
                fontWeight="500"
                size="md"
              />
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Perfil de usuário
              </FormLabel>
              <Select
                value={formData.role}
                name="role"
                onChange={handleChange}
                isRequired={false}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Selecione o perfil de usuário"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                {formData.role === 'super-admin' && (
                  <>
                    <option name="main" value="super-admin">
                      Master
                    </option>
                    <option name="main" value="admin">
                      Gestor
                    </option>
                  </>
                )}
                <option name="main" value="main">
                  Administrador
                </option>
                <option name="worker" value="worker">
                  Colaborador
                </option>
              </Select>

              <Button type="submit" fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px">
                {user && user.id ? 'Atualizar usuário' : 'Adicionar usuário'}
              </Button>
              {sendAlert === 'success' && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Sucesso!</AlertTitle>
                  <AlertDescription>Dados enviados com sucesso!</AlertDescription>
                </Alert>
              )}
              {sendAlert === 'error' ||
                (isDataExists && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Erro!</AlertTitle>
                    <AlertDescription>Verifique os dados digitados e tente novamente.</AlertDescription>
                  </Alert>
                ))}
            </FormControl>
          </form>
        </Card>
        {user && user.id && <AtualizarSenha />}
      </SimpleGrid>
    </Box>
  )
}
