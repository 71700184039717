import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, Grid, GridItem, IconButton, Text } from '@chakra-ui/react'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Importe o CSS do Quill
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { MdEdit } from 'react-icons/md'
import TemplateForm from './components/TemplateForm'

const TemplatesPage = () => {
  const AccountId = getUserAccountId()
  const [templateList, setTemplateList] = useState([])

  useEffect(() => {
    getAccountTemplates()
  }, [])

  async function getAccountTemplates() {
    if (!AccountId) return

    try {
      const response = await axios.get(`/templates?account_id=${AccountId}`, { headers: authHeader() })
      setTemplateList(response.data.data)
      console.log('getAccountTemplates', response)
    } catch (error) {
      console.log('Erro ao carregar os templates', error)
    }
  }

  return (
    <Box gap="20px" pt={'80px'}>
      <Flex
        background={'white'}
        borderRadius={'50px'}
        p="10px 10px 10px 20px"
        alignItems={'center'}
        justifyContent={'space-between'}
        mb="20px"
      >
        <Text fontSize="xl" fontWeight={'800'}>
          Templates
        </Text>
        <Button borderRadius={'50px'} onClick={() => (window.location.href = `/admin/template/new`)}>
          Novo
        </Button>
      </Flex>
      {templateList && (
        <Flex gap={'20px'} flexWrap={'wrap'} mb={'20px'}>
          {templateList &&
            templateList.map((template) => (
              <Flex
                background={'white'}
                borderRadius={'20px'}
                p="20px"
                width={'290px'}
                key={template.id}
                flexDirection={'column'}
              >
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <Text fontWeight={'800'}>{template.name}</Text>
                  <IconButton
                    // variant="outline"
                    // colorScheme="teal"
                    aria-label="Call Sage"
                    fontSize="16px"
                    icon={<MdEdit />}
                    onClick={() => (window.location.href = `/admin/template/${template.id}`)}
                  />
                </Flex>
                <Text>{template.channel}</Text>
              </Flex>
            ))}
        </Flex>
      )}
      {/* <TemplateForm /> */}
    </Box>
  )
}

export default TemplatesPage
