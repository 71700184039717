import React, { useState, useRef } from 'react'
import {
  Box,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Table,
  Tag,
  TagLabel,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'
import { Toast } from 'primereact/toast'
import ContactForm from './formOttenSalesCRM'
import { capitalizeText } from 'hooks/capitalizeText'
import { getUserAuthentication } from 'hooks/authService'
import CheckCNPJExists from './consultaCNPJ'
import { formatCNPJ, formatBRL } from 'hooks/formatBRL'
import CompanyForm from 'views/admin/formClient/components/companyForm'

export default function TableConsulta(props) {
  const { tableData } = props
  const [selectedCnpj, setSelectedCnpj] = useState('')
  const [cnpjData, setCnpjData] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const focusInicial = React.useRef()
  const userAuth = getUserAuthentication()
  const loggedUserName = userAuth?.user?.nome

  const handleSelect = async (cnpj) => {
    setSelectedCnpj(cnpj)
    try {
      const response = await fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
      if (response.status == 200) {
        const data = await response.json()
        setCnpjData({ ...cnpjData, [cnpj]: data })
        onOpen()
      } else {
        toastError('Houve um erro ao carregar esses dados.')
      }
    } catch (error) {
      console.error('Error fetching CNPJ data:', error)
    }
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <Box>
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            <Th>Empresa e CNPJ</Th>
            <Th>Município, UF</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((row, index) => (
            <Tr key={index}>
              <Td>
                <Flex flexDirection={'column'}>
                  <Text color={'#1A202C'} fontSize={'1rem'} fontWeight={'700'}>
                    {row.nome_fantasia && capitalizeText(row.nome_fantasia)}
                    {!row.nome_fantasia && capitalizeText(row.razao_social)}
                  </Text>
                  <Text fontSize={'0.85rem'}>{capitalizeText(row.razao_social)}</Text>
                  <Text color={'#1A202C'} fontSize={'0.75rem'}>
                    CNPJ {formatCNPJ(row.cnpj)}
                  </Text>
                </Flex>
              </Td>
              <Td>{`${capitalizeText(row.municipio)}, ${row.uf}`}</Td>
              <Td>
                <Button
                  onClick={() => handleSelect(row.cnpj)}
                  size="sm"
                  colorScheme="blue"
                  background={'#49BFFF'}
                >
                  Visualizar
                </Button>
                {/* {row.cnpj && <CheckCNPJExists cnpj={row.cnpj} />} */}
                {selectedCnpj === row.cnpj && (
                  <Modal isOpen={isOpen} initialFocusRef={focusInicial} size={'full'} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      {/* <ModalHeader
                        style={{
                          display: 'flex',
                          justifyContent: 'left',
                          padding: '10px 20px',
                          borderBottom: 'solid 1px #eaeaea'
                        }}
                      >
                        <form>
                          <Select
                            size="sm"
                            w="280px"
                            pb="10px"
                            mr={5}
                            defaultValue="VetSales"
                            borderRadius={50}
                          >
                            <option value="VetSales">VetSales</option>
                          </Select>
                        </form>
                        <Text mr={5} fontWeight={500}>
                          {loggedUserName}
                        </Text>
                        <Text fontWeight={500}></Text>
                      </ModalHeader> */}
                      <ModalCloseButton />
                      <ModalBody pt="20px" background={'#e2e8f0'}>
                        {cnpjData[row.cnpj] && (
                          <Box>
                            <SimpleGrid rows={2} rowGap="20px">
                              <SimpleGrid columns={4} columnGap="20px">
                                <Stack>
                                  <Link
                                    isExternal
                                    href={`https://www.google.com/search?q=${
                                      cnpjData[row.cnpj]?.estabelecimento?.nome_fantasia
                                        ? cnpjData[row.cnpj]?.estabelecimento?.nome_fantasia
                                        : cnpjData[row.cnpj]?.razao_social
                                    } ${cnpjData[row.cnpj]?.estabelecimento?.cidade?.nome}`}
                                  >
                                    <Text fontWeight="bold">
                                      {cnpjData[row.cnpj]?.estabelecimento?.nome_fantasia
                                        ? `${capitalizeText(
                                            cnpjData[row.cnpj]?.estabelecimento?.nome_fantasia
                                          )}`
                                        : `${capitalizeText(cnpjData[row.cnpj]?.razao_social)}`}
                                    </Text>
                                    {cnpjData[row.cnpj]?.estabelecimento?.nome_fantasia && (
                                      <Text fontWeight="normal" fontSize="14px">
                                        {capitalizeText(cnpjData[row.cnpj]?.razao_social)}
                                      </Text>
                                    )}
                                  </Link>
                                  {/* <Stack direction="row" wrap={'wrap'}>
                                    <Tag
                                      size="sm"
                                      backgroundColor={'gray.50'}
                                      borderRadius="full"
                                      flexWrap="wrap"
                                    >
                                      <TagLabel>{`${cnpjData[row.cnpj]?.estabelecimento?.tipo}`}</TagLabel>
                                    </Tag>
                                    <Tag
                                      size="sm"
                                      backgroundColor={'gray.50'}
                                      borderRadius="full"
                                      flexWrap="wrap"
                                    >
                                      <TagLabel>{`${cnpjData[row.cnpj]?.estabelecimento?.cidade?.nome}, ${
                                        cnpjData[row.cnpj]?.estabelecimento?.estado?.sigla
                                      }`}</TagLabel>
                                    </Tag>
                                  </Stack> */}
                                </Stack>
                                {/* <Stack>
                                  <Text fontSize="14px" style={{ marginTop: '-2px' }}>
                                    {`CNPJ: ${cnpjData[row.cnpj]?.estabelecimento?.cnpj && formatCNPJ(cnpjData[row.cnpj]?.estabelecimento?.cnpj)}`}
                                  </Text>
                                  <Text fontSize="14px" style={{ textTransform: 'capitalize' }}>{`${
                                    cnpjData[row.cnpj]?.estabelecimento?.tipo_logradouro &&
                                    capitalizeText(cnpjData[row.cnpj].estabelecimento.tipo_logradouro)
                                  } ${cnpjData[row.cnpj]?.estabelecimento?.logradouro && capitalizeText(cnpjData[row.cnpj].estabelecimento.logradouro)} ${
                                    cnpjData[row.cnpj]?.estabelecimento?.numero
                                  }, ${cnpjData[row.cnpj]?.estabelecimento?.bairro && capitalizeText(cnpjData[row.cnpj].estabelecimento.bairro)}`}</Text>
                                  <Text fontSize="14px" style={{ marginTop: '-2px' }}>
                                    {`Cep: ${cnpjData[row.cnpj]?.estabelecimento?.cep}`}
                                  </Text>
                                </Stack> */}
                                {/* <Stack>
                                  <Flex direction="row" wrap={'wrap'} gap="8px">
                                    <Tag
                                      size="sm"
                                      backgroundColor={'gray.50'}
                                      borderRadius="full"
                                      flexWrap="wrap"
                                    >
                                      <TagLabel>{`${
                                        cnpjData[row.cnpj]?.natureza_juridica?.descricao
                                      }`}</TagLabel>
                                    </Tag>

                                    <Tag size="sm" backgroundColor={'gray.50'} borderRadius="full">
                                      <TagLabel>{`${
                                        cnpjData[row.cnpj]?.estabelecimento?.atividade_principal?.subclasse
                                      } - ${
                                        cnpjData[row.cnpj]?.estabelecimento?.atividade_principal?.descricao
                                      }`}</TagLabel>
                                    </Tag>
                                    <Tag
                                      size="sm"
                                      backgroundColor={'gray.50'}
                                      borderRadius="full"
                                      flexWrap="wrap"
                                    >
                                      <TagLabel pt="2px">{`Capital Social R$ ${formatBRL(cnpjData[row.cnpj]?.capital_social)}`}</TagLabel>
                                    </Tag>
                                  </Flex>
                                </Stack> */}
                              </SimpleGrid>
                              {/* <ContactForm dados={cnpjData[row.cnpj]} /> */}
                              <CompanyForm dados={cnpjData[row.cnpj]} />
                            </SimpleGrid>
                          </Box>
                        )}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Toast position="bottom-center" ref={toast} />
    </Box>
  )
}
