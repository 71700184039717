/* eslint-disable */
import { Avatar, Button, Flex, Link, Text, useColorModeValue, Tag } from '@chakra-ui/react'
import {
  FaBuilding,
  FaEnvelope,
  FaInstagram,
  FaLinkedin,
  FaPhone,
  FaSearch,
  FaTwitter,
  FaUserTie
} from 'react-icons/fa'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'
import { getUserAuthenticationRole } from 'hooks/authService'

const userRole = getUserAuthenticationRole()

import React, { useMemo, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { InputText } from 'primereact/inputtext'

export default function Clients(props) {
  const { columnsData, tableData } = props

  function formatPhoneNumber(number) {
    let formattedNumber = number.toString()

    // Remove all non-digit characters
    formattedNumber = formattedNumber.replace(/\D/g, '')

    // Check for country code and format accordingly
    if (formattedNumber.length === 12) {
      // Country code present (e.g., 551151283600)
      const countryCode = formattedNumber.substring(0, 2)
      const areaCode = formattedNumber.substring(2, 4)
      const firstPart = formattedNumber.substring(4, 8)
      const secondPart = formattedNumber.substring(8, 12)
      return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`
    } else if (formattedNumber.length === 13) {
      // Mobile number with country code (e.g., 5521999612288)
      const countryCode = formattedNumber.substring(0, 2)
      const areaCode = formattedNumber.substring(2, 4)
      const firstPart = formattedNumber.substring(4, 9)
      const secondPart = formattedNumber.substring(9, 13)
      return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`
    } else if (formattedNumber.length === 10) {
      // Mobile number without country code (e.g., 21992190830)
      const areaCode = formattedNumber.substring(0, 2)
      const firstPart = formattedNumber.substring(2, 7)
      const secondPart = formattedNumber.substring(7, 11)
      return `55 (${areaCode}) ${firstPart}-${secondPart}`
    } else {
      return 'Número inválido'
    }
  }

  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    first_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    last_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    position: { value: null, matchMode: FilterMatchMode.EQUALS }
  })

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <Flex justifyContent={'space-between'} gap={'10px'}>
        <Text fontSize={'24px'} fontWeight={'800'} color={'#1b2559'}>
          Contatos
        </Text>
        <Flex alignItems={'center'} background={'#f4f7fe'} borderRadius={'20px'}>
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                background: 'transparent'
              }}
            >
              <FaSearch />
            </span>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Buscar"
              v-model="value1"
              style={{
                padding: ' 0px 10px',
                width: '100%',
                background: 'transparent',
                fontSize: '14px'
              }}
              className="outline-none"
            />
          </div>
        </Flex>
        <Flex gap={'10px'} alignItems={'center'}>
          <Link href="/admin/new-contact">
            <Button
              leftIcon={<MdPersonAdd fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Novo
            </Button>
          </Link>
          <Link href="/admin/import-contacts">
            <Button
              leftIcon={<MdImportExport fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Importar
            </Button>
          </Link>
        </Flex>
      </Flex>
    )
  }

  const header = renderHeader()

  const contactName = (item) => {
    return (
      <Link href={`/admin/contact/${item.id}`}>
        <Flex flexDirection={'column'} color="#1b2559">
          <Flex>
            <Text fontSize={'14px'} fontWeight={'600'}>
              {`${item.first_name} ${item.last_name}`}
            </Text>
          </Flex>
          {item.position && <Text fontSize={'14px'}>{item.position}</Text>}
        </Flex>
      </Link>
    )
  }

  const contactData = (item) => {
    return (
      <Flex gap={'5px'} wrap={'wrap'}>
        {item.contact_data.telefone.length > 0 &&
          item.contact_data.telefone.map(
            (phone, index) =>
              phone.numero !== '' && (
                <Link href={`tel:${phone.numero.replace(/\D/g, '')}`} key={index}>
                  <Tag colorScheme="whatsapp" borderRadius={'20px'} pr="12px" py="5px">
                    <FaPhone size={'10px'} style={{ marginRight: '5px' }} />
                    {phone.numero}
                  </Tag>
                </Link>
              )
          )}

        {item.contact_data.email.length > 0 &&
          item.contact_data.email.map(
            (email, index) =>
              email.endereco_email && (
                <Tag key={index} colorScheme="gray" borderRadius={'20px'} pr="12px" py="5px">
                  <FaEnvelope size={'12px'} style={{ marginRight: '5px' }} />
                  {email.endereco_email}
                </Tag>
              )
          )}

        {item.contact_data.linkedin && (
          <Tag colorScheme="linkedin" borderRadius={'20px'} pr="12px" py="5px">
            <FaLinkedin size={'12px'} style={{ marginRight: '5px' }} />
            {item.contact_data.linkedin}
          </Tag>
        )}
        {item.contact_data.instagram && (
          <Tag colorScheme="pink" borderRadius={'20px'} pr="12px" py="5px">
            <FaInstagram size={'12px'} style={{ marginRight: '5px' }} />
            {item.contact_data.instagram}
          </Tag>
        )}
        {item.contact_data.x && (
          <Tag colorScheme="twitter" borderRadius={'20px'} pr="12px" py="5px">
            <FaTwitter size={'12px'} style={{ marginRight: '5px' }} />
            {item.contact_data.x}
          </Tag>
        )}
      </Flex>
    )
  }

  const companyData = (item) => {
    return (
      <Link href={`/admin/company/${item.company_id}`}>
        <Flex color="#1b2559">
          <Text fontSize={'14px'} fontWeight={'600'}>
            {item.company.name}
          </Text>
        </Flex>
      </Link>
    )
  }

  return (
    <Flex gap={'20px'} flexDirection={'column'}>
      <Flex bg={'white'} p={'20px'} borderRadius={'20px'} width={'fit-content'}>
        <DataTable
          value={tableData}
          header={header}
          filters={filters}
          paginator
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="id"
          globalFilterFields={['first_name', 'last_name', 'company.name', 'position']}
          tableStyle={{ width: '100vh' }}
          pt={{
            header: {
              style: {
                background: '#fff'
              }
            }
          }}
        >
          <Column
            field="first_name"
            header="Nome"
            body={contactName}
            style={{ minWidth: '250px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="contact"
            header="Dados de Contato"
            body={contactData}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="company"
            header="Empresa"
            body={companyData}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
        </DataTable>
      </Flex>
    </Flex>
  )
}
