import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Table,
  Tag,
  TagLabel,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'
import ContactForm from './formOttenSalesCRM'
import { capitalizeText } from 'hooks/capitalizeText'
import { getUserAuthentication } from 'hooks/authService'
import CheckCNPJExists from './consultaCNPJ'

export default function ModalProspecao({ cnpj }) {
  // const [selectedCnpj, setSelectedCnpj] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [cnpjData, setCnpjData] = useState({})
  const focusInicial = React.useRef()
  const userAuth = getUserAuthentication()
  const loggedUserName = userAuth?.user?.nome

  useEffect(() => {
    const consultaCnpjUnico = async () => {
      // setSelectedCnpj(cnpj);
      try {
        const response = await fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
        const data = await response.json()
        setCnpjData(data)
        console.log('Fetch Data:', data)
        console.log(
          'CnpjData.estabelecimento.cnpj:',
          cnpjData && cnpjData.estabelecimento && cnpjData.estabelecimento.cnpj
        )
        onOpen()
      } catch (error) {
        console.error('Error fetching CNPJ data:', error)
      }
    }

    consultaCnpjUnico()
  }, [cnpjData?.estabelecimento?.cnpj])

  return (
    <Modal isOpen={isOpen} initialFocusRef={focusInicial} size={'full'} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          style={{
            display: 'flex',
            justifyContent: 'left',
            padding: '10px 20px',
            borderBottom: 'solid 1px #eaeaea'
          }}
        >
          <form>
            <Select size="sm" w="280px" pb="10px" mr={5} defaultValue="VetSales" borderRadius={50}>
              <option value="OttenSales">OttenSales</option>
            </Select>
          </form>
          <Text mr={5} fontWeight={500}>
            {loggedUserName}
          </Text>
          <Text fontWeight={500}></Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="20px" background={'#e2e8f0'}>
          {cnpjData?.estabelecimento?.cnpj ? (
            <Box>
              <SimpleGrid rows={2} rowGap="20px">
                <SimpleGrid columns={4} columnGap="20px">
                  <Stack>
                    <Link
                      isExternal
                      href={`https://www.google.com/search?q=${
                        cnpjData?.estabelecimento?.nome_fantasia
                          ? cnpjData?.estabelecimento?.nome_fantasia
                          : cnpjData?.razao_social
                      } ${cnpjData?.estabelecimento?.cidade?.nome}`}
                    >
                      <Text fontWeight="bold">
                        {cnpjData?.cnpj?.estabelecimento?.nome_fantasia
                          ? `${capitalizeText(cnpjData?.estabelecimento?.nome_fantasia)}`
                          : `${capitalizeText(cnpjData?.razao_social)}`}
                      </Text>
                      {cnpjData?.estabelecimento?.nome_fantasia && (
                        <Text fontWeight="normal" fontSize="14px">
                          {capitalizeText(cnpjData.razao_social)}
                        </Text>
                      )}
                    </Link>
                    <Stack direction="row" wrap={'wrap'}>
                      <Tag size="sm" backgroundColor={'gray.50'} borderRadius="full" flexWrap="wrap">
                        <TagLabel>{`${cnpjData.estabelecimento.tipo}`}</TagLabel>
                      </Tag>
                      <Tag size="sm" backgroundColor={'gray.50'} borderRadius="full" flexWrap="wrap">
                        <TagLabel>{`${cnpjData.estabelecimento.cidade.nome}, ${cnpjData.estabelecimento.estado.sigla}`}</TagLabel>
                      </Tag>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Text fontSize="14px" style={{ marginTop: '-2px' }}>
                      {`CNPJ: ${cnpjData.estabelecimento.cnpj}`}
                    </Text>
                    <Text fontSize="14px" style={{ textTransform: 'capitalize' }}>{`${capitalizeText(
                      cnpjData.estabelecimento.tipo_logradouro
                    )} ${capitalizeText(cnpjData.estabelecimento.logradouro)} ${
                      cnpjData.estabelecimento.numero
                    }, ${capitalizeText(cnpjData.estabelecimento.bairro)}`}</Text>
                    <Text fontSize="14px" style={{ marginTop: '-2px' }}>
                      {`Cep: ${cnpjData.estabelecimento.cep}`}
                    </Text>
                  </Stack>
                  <Stack>
                    <Stack direction="row" wrap={'wrap'}>
                      <Tag size="sm" backgroundColor={'gray.50'} borderRadius="full" flexWrap="wrap">
                        <TagLabel>{`${cnpjData.natureza_juridica.descricao}`}</TagLabel>
                      </Tag>
                      <Tag size="sm" backgroundColor={'gray.50'} borderRadius="full">
                        <TagLabel>{`CS R$ ${cnpjData.capital_social}`}</TagLabel>
                      </Tag>
                    </Stack>
                  </Stack>
                </SimpleGrid>
                <ContactForm dados={cnpjData} />
              </SimpleGrid>
            </Box>
          ) : (
            <Text>Nenhum dado disponível</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
