import axios from '../api/axios'
import SERVER_URL from '../api/serverUrl'
import authHeader from './authHeader'
import { useState } from 'react'

export async function loginLocal(email, password, signInData) {
  try {
    const response = await axios.post('/authentication', {
      email: email,
      password: password,
      strategy: 'local'
    })

    const { accessToken, user } = response && response.data

    if (accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data))
      console.log(`Olá ${user.first_namee}!`, response.data)

      if (signInData && signInData.cpf) {
        const patch = await axios.patch(`/users/${user.id}`, signInData, {
          headers: authHeader()
        })

        console.log('patch 1: ', patch.data)
      }

      setTimeout(() => {
        window.location.href = '/admin/painel'
      }, 900)
    }

    return response
  } catch (error) {
    console.error('verifique os dados de login e senha.', error)
    return error
  }
}

export async function newMainUser(name, email, password, user_data) {
  const { phone1 } = user_data
  const whatsappMessage = `${name}, seja bem vindo! Esse é o nosso canal de atendimento principal. Salve este número na sua agenda.`

  const role = 'main'

  const whastappMessageToken = {
    number: phone1,
    options: {
      delay: 0,
      presence: 'composing',
      linkPreview: false
    },
    textMessage: {
      text: whatsappMessage
    }
  }

  // if (user_data && user_data.phone1) {
  //   try {
  //     const response = await fetch('https://wapi.conta.com.vc/message/sendText/1151283600', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         apikey: 'wyjrdjr2zqj4fdw5fi53nu'
  //       },
  //       body: JSON.stringify(whastappMessageToken)
  //     })

  //     if (!response.ok) {
  //       throw new Error(`Erro na requisição: ${response.statusText}`)
  //     }

  //     const data = await response.json()
  //     console.log('Mensagem enviada pelo WhatsApp:', data)
  //   } catch (error) {
  //     console.error('Erro ao enviar o texto para a API:', error)
  //     throw error
  //   }
  // }

  const addUserResponse = await axios.post('/users', {
    first_name: name,
    email,
    user_data,
    password,
    role
  })

  console.log('addUserResponse: ', addUserResponse)

  return addUserResponse
}

export function registerUser(nome, email, password, role) {
  return axios.post('/users', {
    nome,
    email,
    password,
    role
  })
}

export function logout() {
  localStorage.removeItem('user')
  window.location.href = '/'
}

// export function getUserAuthentication() {
//   // let authenticationLocalStorageData
//   const userData = JSON.parse(localStorage.getItem('user'))
//   const expirationDate = parseInt(userData?.authentication?.payload?.exp, 10)
//   const timeNow = parseInt(Math.floor(new Date().getTime() / 1000), 10)
//   const audience = userData?.authentication?.payload?.aud

//   console.log(`now: ${timeNow}, expirationDate: ${expirationDate}, audience: ${audience}`)
//   // console.log(`userData:`, userData)
//   // console.log('Token Valid: ', expirationDate >= timeNow)
//   // console.log('Audience valid: ', audience === 'https://vetsales.com.br')

//   console.log('Check audience at authService.js')
//   console.log('typeof (expirationDate)', typeof expirationDate, isNaN(expirationDate))

//   if (
//     isNaN(expirationDate) === false &&
//     expirationDate > timeNow &&
//     audience === 'https://sales.otten.com.br'
//   ) {
//     return userData
//   }
//   return false
// }

export function getUserAuthentication() {
  try {
    const userData = JSON.parse(localStorage.getItem('user'))

    // Extração segura dos dados de autenticação
    const expirationTimestamp = userData?.authentication?.payload?.exp
    const audience = userData?.authentication?.payload?.aud

    // Conversão do timestamp para segundos e comparação com o tempo atual
    const expirationDate = expirationTimestamp ? parseInt(expirationTimestamp, 10) : NaN
    const currentTime = Math.floor(Date.now() / 1000)

    // Logs para auxiliar na depuração (remova em produção)
    // console.log(`now: ${currentTime}, expirationDate: ${expirationDate}, audience: ${audience}`)

    // Verificação da validade do token e da audiência
    const isTokenValid = !isNaN(expirationDate) && expirationDate > currentTime
    const isAudienceValid = audience === 'https://sales.otten.com.br'

    if (isTokenValid && isAudienceValid) {
      return userData
    }

    return false
  } catch (error) {
    // Lidar com erros de forma apropriada, como logá-los ou exibir uma mensagem ao usuário
    console.error('Erro ao obter dados de autenticação:', error)
    return false // Ou outro valor que indique falha na autenticação
  }
}

export function getUserAccountId() {
  const userToken = JSON.parse(localStorage.getItem('user'))
  const accountId = userToken?.user?.user_data?.main_account_id
  return accountId
}

export function getUserAuthenticationRole() {
  const userToken = JSON.parse(localStorage.getItem('user'))
  const role = userToken?.user?.role
  return 'main'
}
