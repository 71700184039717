import React, { useEffect, useState } from 'react'
// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useEditable
} from '@chakra-ui/react'
import {
  MdBarChart,
  MdPerson,
  MdGroup,
  MdHome,
  MdCreditCard,
  MdVideocam,
  MdTrackChanges,
  MdAssistant,
  MdChat,
  MdPersonAdd,
  MdPlaylistPlay,
  MdContacts,
  MdNotInterested,
  MdSettings,
  MdLogout,
  MdBusiness
} from 'react-icons/md'
import NewUser from 'views/auth/newUser'
import { logout, getUserAuthentication } from 'hooks/authService'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { Redirect, useLocation } from 'react-router-dom'

// Custom Components
import { ItemContent } from 'components/menu/ItemContent'
// import { SearchBar } from 'components/navbar/searchBar/SearchBar'
import { SidebarResponsive } from 'components/sidebar/Sidebar'
import PropTypes from 'prop-types'
import SignInForm from '../../views/auth/signIn/components/SignInForm'

// Assets
import navImage from 'assets/img/layout/Navbar.png'
import { MdNotificationsNone, MdInfoOutline, MdLock, MdPets } from 'react-icons/md'
import routes from 'routes.js'
import { ThemeEditor } from './ThemeEditor'

export default function HeaderLinks(props) {
  const { secondary } = props
  const location = useLocation()
  console.log('location', location)

  const userData = getUserAuthentication()
  // console.log('Usuário', userData.user)

  const [accountData, setAccountData] = useState({})

  useEffect(() => {
    async function getAccountData() {
      try {
        const { data } = await axios.get(`/accounts/${accountId}`, {
          headers: authHeader()
        })
        // console.log('account Data é:', data)
        setAccountData(data)
      } catch (error) {
        console.log('Erro ao carregar dados da conta:', error)
      }
    }

    if (userData) {
      getAccountData()
    }
  }, [])

  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white')
  let menuBg = useColorModeValue('white', 'navy.800')
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const textColorBrand = useColorModeValue('brand.700', 'brand.400')
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)')
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  )
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200')

  const urlRoute = window.location.href
  const routePublicHome = urlRoute.includes('/public/home')
  console.log('routePublicHome', routePublicHome)

  const [logged, setLogged] = useState(false)

  if (!userData && !routePublicHome) {
    setLogged(false)
    window.location.href = '/public/home'
    return
  }

  if (!userData) {
    return null
    // <Flex
    //   w={{ sm: '100%', md: 'auto' }}
    //   alignItems="center"
    //   flexDirection="row"
    //   bg={menuBg}
    //   flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
    //   p="10px"
    //   borderRadius="30px"
    //   boxShadow={shadow}
    // >
    //   <Menu>
    //     <MenuButton p="0px">
    //       <Link href="/auth/sign-up">
    //         <Flex alignItems="center" px={3}>
    //           <Icon as={MdLock} color={navbarIcon} w="18px" h="18px" me="10px" />
    //           <Text>Criar conta</Text>
    //         </Flex>
    //       </Link>
    //     </MenuButton>
    //   </Menu>
    //   <Menu>
    //     <MenuButton p="0px" px={3}>
    //       <Flex alignItems="center">
    //         <Icon as={MdLock} color={navbarIcon} w="18px" h="18px" me="10px" />
    //         <Text>Entrar</Text>
    //       </Flex>
    //     </MenuButton>
    //     <MenuList
    //       boxShadow={shadow}
    //       p="20px"
    //       borderRadius="20px"
    //       bg={menuBg}
    //       border="none"
    //       mt="22px"
    //       me={{ base: '30px', md: 'unset' }}
    //       minW={{ base: 'unset', md: '400px', xl: '450px' }}
    //       maxW={{ base: '360px', md: 'unset' }}
    //     >
    //       <SignInForm />
    //     </MenuList>
    //   </Menu>
    // </Flex>
  }

  // Se autenticado começa daqui
  const {
    user: {
      first_name,
      id: userId,
      role,
      user_data: { main_account_id: accountId }
    }
  } = userData

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      {accountData && accountData.name && (
        <>
          <Text style={{ padding: '5px 20px', fontWeight: '800' }}>{accountData.name}</Text>
          {/* <SearchBar mb={secondary ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="30px" /> */}
          <SidebarResponsive routes={routes} />

          {
            // Redirect to main admin page if authenticated
            routePublicHome && window.location.replace('/admin/painel')
          }

          <Menu>
            <MenuButton p="0px">
              <Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p="20px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
              mt="22px"
              me={{ base: '30px', md: 'unset' }}
              minW={{ base: 'unset', md: '400px', xl: '450px' }}
              maxW={{ base: '360px', md: 'unset' }}
            >
              <Flex jusitfy="space-between" w="100%" mb="20px">
                <Text fontSize="md" fontWeight="600" color={textColor}>
                  Notificações
                </Text>
                <Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
                  Limpar
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
                  <ItemContent
                    title="Estamos em desenvolvimento"
                    info="Novas atualizações em breve"
                    aName="VetSales"
                  />
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </>
      )}

      {/* <ThemeEditor navbarIcon={navbarIcon} /> */}

      {first_name && (
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              name={first_name && first_name}
              bg="#0D4384"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
            <>
              <Flex w="100%" mb="0px">
                <Text
                  ps="20px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="700"
                  color={textColor}
                >
                  {first_name ? `👋 Olá, ${first_name && first_name}` : 'Crie sua conta'}
                </Text>
              </Flex>
              {first_name && (
                <Flex flexDirection="column" p="10px" alignItems="center">
                  <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                    <Link href={`/admin/user/${userId}`}>
                      <Flex flexDirection="row">
                        <Icon as={MdPerson} width="20px" height="20px" color="#8F9BBA" mr="5px" />
                        <Text fontSize="sm">Meus dados</Text>
                      </Flex>
                    </Link>
                  </MenuItem>
                  <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                    <Link href={`/admin/account-profile/${accountId}`}>
                      <Flex flexDirection="row">
                        <Icon as={MdBusiness} width="20px" height="20px" color="#8F9BBA" mr="5px" />
                        <Text fontSize="sm">Minha Empresa</Text>
                      </Flex>
                    </Link>
                  </MenuItem>
                  <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                    <Link href={`/admin/payments/${accountId}`}>
                      <Flex flexDirection="row">
                        <Icon as={MdCreditCard} width="20px" height="20px" color="#8F9BBA" mr="5px" />
                        <Text fontSize="sm">Financeiro</Text>
                      </Flex>
                    </Link>
                  </MenuItem>
                  <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                    <Link href={`/admin/settings/${accountId}`}>
                      <Flex flexDirection="row">
                        <Icon as={MdSettings} width="20px" height="20px" color="#8F9BBA" mr="5px" />
                        <Text fontSize="sm">Configurações</Text>
                      </Flex>
                    </Link>
                  </MenuItem>
                  <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                    <Link href={`/public/default`}>
                      <Flex flexDirection="row">
                        <Icon as={MdLogout} width="20px" height="20px" color="#8F9BBA" mr="5px" />
                        <Text
                          fontSize="sm"
                          onClick={() => {
                            logout()
                          }}
                        >
                          Sair
                        </Text>
                      </Flex>
                    </Link>
                  </MenuItem>

                  {/* <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    borderRadius='8px'
                    px='14px'
                  >
                    <Text fontSize='sm'>Alterar senha</Text>
                  </MenuItem> */}
                </Flex>
              )}
            </>
          </MenuList>
        </Menu>
      )}
    </Flex>
  )
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
}
