import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormHelperText,
  GridItem,
  Grid,
  InputRightElement,
  IconButton,
  Icon,
  Button,
  Checkbox,
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Progress,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
  Tag,
  Text
} from '@chakra-ui/react'
import {
  FaPhone,
  FaFlag,
  FaEnvelope,
  FaTasks,
  FaRegCalendarAlt,
  FaRegEnvelope,
  FaCommentAlt,
  FaComment,
  FaBriefcase,
  FaWhatsapp,
  FaBuilding,
  FaUserTie,
  FaMapMarkerAlt
} from 'react-icons/fa'
import { SelectButton } from 'primereact/selectbutton'
import { InputTextarea } from 'primereact/inputtextarea'

export function ActiveActivityCard({ selectedActivity, handleChange }) {
  function getActivityIcon(activityType) {
    switch (activityType) {
      case 'call':
        return <FaPhone color="white" />
      case 'task':
        return <FaTasks color="white" />
      case 'meeting':
        return <FaRegCalendarAlt color="white" />
      case 'visit':
        return <FaMapMarkerAlt color="white" />
      case 'email':
        return <FaRegEnvelope color="white" />
      case 'message':
        return <FaCommentAlt color="white" />
      default:
        return null
    }
  }

  const prioritiesList = [
    {
      value: 'Baixa prioridade',
      flag: 'low',
      color: '#49BFFF'
    },
    {
      value: 'Prioridade normal',
      flag: 'normal',
      color: '#e9e9e9'
    },
    {
      value: 'Alta prioridade',
      flag: 'high',
      color: '#F6E05E'
    },
    {
      value: 'Máxima prioridade ',
      flag: 'top',
      color: '#E53E3E'
    }
  ]

  function getColorByFlag(flag) {
    const priority = prioritiesList.find((item) => item.flag === flag)
    return priority ? priority.color : 'transparent'
  }

  return (
    <Flex
      flexDirection="column"
      bg="white"
      borderRadius={'20px'}
      p="20px"
      mt="20px"
      w="600px"
      h="fit-content"
      boxShadow={'lg'}
    >
      {!selectedActivity.type && (
        <Flex>
          <Text fontSize={'lg'} fontWeight={'800'} mb="0px">
            Não há atividades cadastradas.
          </Text>
        </Flex>
      )}
      {selectedActivity.type && (
        <Grid templateColumns="repeat(5, 1fr)" gap={5}>
          <GridItem w="100%" colSpan={4}>
            <Flex gap={3} alignItems={'center'} mb="20px">
              <FaBriefcase color="#8f9bba" />
              <Text>{`${selectedActivity.deal_name}`}</Text>
              <IconButton
                colorScheme={'white'}
                color={getColorByFlag(selectedActivity.flag)}
                // variant="outline"
                aria-label="Priority"
                borderRadius={'50px'}
                size="sm"
                p="1px"
                w="20px"
                icon={<Icon as={FaFlag} />}
              />
            </Flex>
            <Flex gap={3} alignItems={'center'}>
              <FaUserTie color="#8f9bba" />
              <Text
                fontWeight={'800'}
              >{`${selectedActivity.contact_first_name} ${selectedActivity.contact_last_name}, ${selectedActivity.contact_position}`}</Text>
            </Flex>
            <Flex gap={3} alignItems={'center'}>
              <FaBuilding color="#8f9bba" />
              <Text>{selectedActivity.company_name}</Text>
            </Flex>

            <Flex flexDirection={'row'} mt="20px" alignItems="center">
              <Box bg={'#4dadd7'} p="10px" borderRadius="50px" mr="10px" w="37px" h="37px">
                {selectedActivity.type && getActivityIcon(selectedActivity.type)}
              </Box>
              <Flex flexDirection={'column'}>
                {/* <Text>{selectedActivity.deal_name}</Text> */}
                <Text>{selectedActivity.deal_description}</Text>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem
            w="100%"
            style={{ display: 'flex', justifyContent: 'flex-end', height: 'fit-content' }}
            colSpan={1}
          >
            <Flex flexDirection={'column'} alignItems={'flex-end'}>
              <Flex gap={3}>
                <Tag
                  bg="#E2E8F0"
                  w={'fit-content'}
                  fontSize={'0.75rem'}
                  borderRadius={'20px'}
                  mb="20px"
                >{`${selectedActivity?.step}`}</Tag>
              </Flex>

              <Flex flexDirection={'row '} alignItems={'start'} gap="10px" ml="10px">
                {selectedActivity.contact_phone && (
                  <Link href={`tel:${selectedActivity.contact_phone}`}>
                    <Flex p="10px" ml="3px" bg="gray.200" borderRadius={'20px'} alignItems={'center'}>
                      <FaPhone />
                    </Flex>
                  </Link>
                )}
                {selectedActivity.contact_phone_type === 'whatsapp' && (
                  <Link href={`mailto:${selectedActivity.contact_phone}`}>
                    <Flex p="10px" ml="3px" bg="gray.200" borderRadius={'20px'} alignItems={'center'}>
                      <FaWhatsapp />
                    </Flex>
                  </Link>
                )}
                {selectedActivity.contact_email && (
                  <Link href={`mailto:${selectedActivity.contact_email}`}>
                    <Flex p="10px" ml="3px" bg="gray.200" borderRadius={'20px'} alignItems={'center'}>
                      <FaEnvelope />
                    </Flex>
                  </Link>
                )}
              </Flex>
            </Flex>
          </GridItem>
          <GridItem w="100%" colSpan={5}>
            <Flex w="100%">
              <FaComment color="#8f9bba" className="mr-3" size={'14px'} />
              <InputTextarea rows={2} cols={65} placeholder="Escreva um comentário..." />
            </Flex>
          </GridItem>
          <GridItem w="100%" colSpan={5}>
            <SelectButton
              name="status"
              value={selectedActivity.status}
              onChange={handleChange}
              options={[
                { value: 'todo', name: 'Pendente' },
                // { value: 'doing', name: 'Fazendo' },
                // { value: 'blocked', name: 'Impedida' },
                { value: 'postponed', name: 'Adiada' },
                { value: 'canceled', name: 'Cancelada' },
                { value: 'done', name: 'Realizada' }
              ]}
              optionLabel="name"
              style={{
                display: 'flex',
                fontSize: '12px',
                width: '100%',
                margin: '0px 0 0px 0px',
                justifyContent: 'flex-end'
              }}
              pt={{
                button: {
                  style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
                },
                label: { style: { fontSize: '12px', fontWeight: '500' } }
              }}
            />
          </GridItem>
        </Grid>
      )}
    </Flex>
  )
}
