import React, { useState, useEffect } from 'react'
import { Flex, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { FaAddressBook } from 'react-icons/fa'
import { MultiSelect } from 'primereact/multiselect'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { getUserAccountId } from 'hooks/authService'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'

export default function QualificationForm() {
  const textColor = useColorModeValue('navy.700', 'white')
  const accountId = getUserAccountId()
  const location = useLocation()
  const companyId = parseInt(location.pathname.replace('/admin/company/', ''), 10)

  const [companyContactList, setCompanyContactList] = useState([])
  const companyContactsTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{`${option.first_name} ${option.last_name}`} </div>
      </div>
    )
  }

  const [formData, setFormData] = useState({
    desafio: [],
    tentativas: [],
    urgencia: '', // enum [imediato, em 30 dias, 3 meses, 6 meses, 9 meses, 1 ano]
    colaboradores: '',
    faturamento: '',
    orçamento: '', //
    decisores: [], //
    criterio_decisao: [], // preço, qualidade, suporte ?
    concorrentes: [] //
  })

  useEffect(() => {
    getCompanyContacts()
  }, [])

  async function getCompanyContacts() {
    if (!companyId) return
    if (!accountId) return

    try {
      const response = await axios.get(`/contacts?account_id=${accountId}&company_id=${companyId}`, {
        headers: authHeader()
      })
      setCompanyContactList(response.data.data)
      console.log('Company Contacts:', response.data)
    } catch (error) {}
  }

  function handleChange(event) {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    <Flex bg={'white'} p="20px" borderRadius={'20px'} flexDirection={'column'}>
      <Text display="flex" fontSize="xl" fontWeight="800" color={textColor} mb="20px">
        Qualificação
      </Text>
      <Flex flexDirection={'column'}>
        <Grid templateColumns="repeat(4,1fr)" gap="20px">
          <GridItem colSpan={4}>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Qual o principal desafio na área de vendas?
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaAddressBook />
                </span>

                <InputText
                  value={formData.desafio}
                  name="desafio"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>

            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Quais soluções já foram tentadas?
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaAddressBook />
                </span>

                <InputText
                  value={formData.tentativas}
                  name="tentativas"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
            <label
              style={{
                color: '#A0AEC0',
                fontSize: '12px',
                fontWeight: '500',
                paddingLeft: '0px'
              }}
            >
              Qual o faturamento anual?
            </label>
            <Flex py="0.3rem" mt="-5px" alignItems={'center'}>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <FaAddressBook />
                </span>

                <InputText
                  value={formData.faturamento}
                  name="faturamento"
                  onChange={handleChange}
                  placeholder="..."
                  v-model="value1"
                  style={{
                    padding: ' 0px 10px',
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }}
                  className="outline-none"
                />
              </div>
            </Flex>
            <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
              Número de colaboradores
            </label>
            <Dropdown
              value={formData.colaboradores}
              name="colaboradores"
              onChange={handleChange}
              placeholder="Selecione"
              options={[
                { value: '1 a 10', name: '1 a 10 Funcionários' },
                { value: '10 a 30', name: '10 a 30 Funcionários' },
                { value: '30 a 70', name: '30 a 70 Funcionários' },
                { value: '70 a 200', name: '70 a 200 Funcionários' },
                { value: '200 a 500', name: '200 a 500 Funcionários' },
                { value: '+500', name: '+500' }
              ]}
              optionLabel="name"
              className="w-full"
              pt={{
                root: { background: '#fafafa' },
                input: {
                  style: {
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                trigger: {
                  style: {
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                item: {
                  style: {
                    fontSize: '14px',
                    padding: '8px 16px',
                    width: '100%',
                    background: '#fafafa'
                  }
                },
                panel: {
                  style: { marginTop: '5px' }
                }
              }}
            />
            <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
              Qual a urgência em resolver o desafio?
            </label>
            <Dropdown
              value={formData.urgencia}
              name="urgencia"
              onChange={handleChange}
              options={[
                { value: 0, name: 'Imediato' },
                { value: 30, name: 'Em 1 mês' },
                { value: 90, name: 'Em 3 meses' },
                { value: 180, name: 'Em 6 meses' },
                { value: 270, name: 'Em 9 meses' },
                { value: 360, name: 'Em 12 meses' }
              ]}
              placeholder="Selecione"
              optionLabel="name"
              display="chip"
              className="w-full"
              pt={{
                root: { background: '#fafafa' },
                input: {
                  style: {
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                trigger: {
                  style: {
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                item: {
                  style: {
                    fontSize: '14px',
                    padding: '8px 16px',
                    width: '100%',
                    background: '#fafafa'
                  }
                },
                panel: {
                  style: { marginTop: '5px' }
                }
              }}
            />
            <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
              Qual o orçamento disponível para resolver o problema?
            </label>
            <Dropdown
              value={formData.orçamento}
              name="orçamento"
              onChange={handleChange}
              options={[
                { value: 'Não sabe', name: 'Não sabe' },
                { value: 'Depende do custo/benefício', name: 'Depende do custo/benefício' },
                { value: 'R$ 10mil', name: 'R$ 10mil' },
                { value: 'R$ 30mil', name: 'R$ 30mil' },
                { value: 'R$ 60mil', name: 'R$ 60mil' },
                { value: 'R$ 90mil', name: 'R$ 90mil' },
                { value: 'R$ 150mil', name: 'R$ 150mil' },
                { value: 'R$ 200mil', name: 'R$ 200mil' }
              ]}
              placeholder="Selecione"
              optionLabel="name"
              display="chip"
              className="w-full"
              pt={{
                root: { background: '#fafafa' },
                input: {
                  style: {
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                trigger: {
                  style: {
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                item: {
                  style: {
                    fontSize: '14px',
                    padding: '8px 16px',
                    width: '100%',
                    background: '#fafafa'
                  }
                },
                panel: {
                  style: { marginTop: '5px' }
                }
              }}
            />
            <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
              Quais os critérios para a tomada de decisão?
            </label>
            <MultiSelect
              value={formData.criterio_decisao}
              name="criterio_decisao"
              onChange={handleChange}
              options={[
                { value: 'Preço', name: 'Preço' },
                { value: 'Qualidade', name: 'Qualidade' },
                { value: 'Suporte', name: 'Suporte ao cliente' },
                { value: 'Implementação', name: 'Facilidade de Implementação' },
                { value: 'Reputação', name: 'Reputação do Fornecedor' },
                { value: 'ROI', name: 'Retorno sobre o Investimento' }
              ]}
              placeholder="Selecione"
              optionLabel="name"
              display="chip"
              className="w-full"
              showSelectAll={false}
              pt={{
                root: { background: '#fafafa' },
                input: {
                  style: {
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                trigger: {
                  style: {
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                token: {
                  style: {
                    background: '#fafafa',
                    fontSize: '12px'
                  }
                },
                item: {
                  style: {
                    fontSize: '14px',
                    padding: '8px 16px',
                    width: '100%',
                    background: '#fafafa'
                  }
                },
                panel: {
                  style: { marginTop: '5px' }
                }
              }}
            />
            <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
              Quem são os decisores?
            </label>
            <MultiSelect
              value={formData.decisores}
              name="decisores"
              onChange={handleChange}
              options={companyContactList}
              itemTemplate={companyContactsTemplate}
              optionLabel={companyContactsTemplate}
              placeholder="Selecione"
              display="chip"
              className="w-full"
              showClear={false}
              showSelectAll={false}
              pt={{
                root: { background: '#fafafa' },
                input: {
                  style: {
                    width: '100%',
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                trigger: {
                  style: {
                    background: '#fafafa',
                    fontSize: '14px'
                  }
                },
                token: {
                  style: {
                    background: '#fafafa',
                    fontSize: '12px'
                  }
                },
                item: {
                  style: {
                    fontSize: '14px',
                    padding: '8px 16px',
                    width: '100%',
                    background: '#fafafa'
                  }
                },
                panel: {
                  style: { marginTop: '5px' }
                }
              }}
            />
            {/* <p>
              Tem time comercial ativo, como faz? quantas empresas prospecta/dia? tem controle de vendas? tem
              processo comercial interno? Usa CRM?
            </p>
            <p>Estrutura digital? site, redes sociais, anuncios?</p> */}
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  )
}
