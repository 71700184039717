import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  IconButton,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Textarea,
  Select,
  useColorModeValue,
  Text,
  GridItem
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import formatDate from 'hooks/formatDate'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import ApproachForm from '../../newAproach/components/newApproachForm'
import NewCampaignScript from './campaignScript'
import { getUserAccountId } from 'hooks/authService'
import IntervalInput from 'components/intervalInput'
import AddTermToInput from 'components/addTermToInput'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { InputMask } from 'primereact/inputmask'
import { InputNumber } from 'primereact/inputnumber'

export function CampaignActivitiesForm({
  formData,
  setFormData,
  accountName,
  pipelineSteps,
  setPipelineSteps
}) {
  const accountId = getUserAccountId()
  const textColor = useColorModeValue('navy.700', 'white')
  const textareaRefs = useRef({})

  console.log('CampaignActivitiesForm formData ', formData)

  const [selectedActivity, setSelectedActivity] = useState('')
  const [days, setDays] = useState(2)
  const [startHour, setStartHour] = useState('09:00')
  const [endHour, setEndHour] = useState('16:00')

  const [templateList, setTemplateList] = useState([''])

  useEffect(() => {
    // console.log('templateList: ', templateList)
    console.log('endHour', endHour)
  }, [templateList, endHour])

  // Se campanha nova

  // Se possui Campaign_Id

  // Handlers
  const handleAddActivity = useCallback((stepName, activity) => {
    if (!activity) return
    if (activity) {
      setFormData((prev) => {
        const updatedActivities = {
          ...prev.pipeline_activities,
          [stepName]: [...(prev.pipeline_activities[stepName] || []), { activity, note: '' }]
        }

        if (!textareaRefs.current) textareaRefs.current = {}
        textareaRefs.current[`${stepName}-${updatedActivities[stepName].length - 1}`] = React.createRef()
        setSelectedActivity((prevSelectedActivities) => ({
          ...prevSelectedActivities,
          [stepName]: '' // Reset o valor do select após adicionar a atividade
        }))
        return {
          ...prev,
          pipeline_activities: updatedActivities
        }
      })
    }
  }, [])

  const handleSelectActivity = (stepName, event) => {
    const activity = event.target.value

    setSelectedActivity((prevSelectedActivities) => ({
      ...prevSelectedActivities,
      [stepName]: activity
    }))
  }

  const handleRemoveActivity = useCallback((stepName, index) => {
    setFormData((prev) => {
      const updatedActivities = { ...prev.pipeline_activities }
      updatedActivities[stepName].splice(index, 1)

      delete textareaRefs.current[`${stepName}-${index}`]

      return {
        ...prev,
        pipeline_activities: updatedActivities
      }
    })
  }, [])

  const handleNoteChange = (stepName, index, note) => {
    setFormData((prev) => {
      const updatedActivities = [...prev.pipeline_activities[stepName]]
      updatedActivities[index] = { ...updatedActivities[index], note }
      return {
        ...prev,
        pipeline_activities: {
          ...prev.pipeline_activities,
          [stepName]: updatedActivities
        }
      }
    })
  }

  useEffect(() => {
    getAccountTemplates()
  }, [])

  async function getAccountTemplates() {
    if (!accountId) return

    try {
      const response = await axios.get(`/templates?account_id=${accountId}`, { headers: authHeader() })
      setTemplateList(response.data.data)
      // console.log('getAccountTemplates', response)
    } catch (error) {
      console.log('Erro ao carregar os templates', error)
    }
  }

  const handleTemplateChange = (stepName, index, event) => {
    const selectedValue = event.target.value

    setFormData((prev) => ({
      ...prev,
      pipeline_activities: {
        ...prev.pipeline_activities,
        [stepName]: prev.pipeline_activities[stepName].map((activity, i) =>
          i === index ? { ...activity, template_id: selectedValue } : activity
        )
      }
    }))
  }

  const [appointment, setAppointment] = useState(false)
  const [scheduleInterval, setScheduleInterval] = useState(0)

  const handleTemplateScheduleChange = (stepName, index, event) => {
    const { name, value } = event.target

    setFormData((prev) => ({
      ...prev,
      pipeline_activities: {
        ...prev.pipeline_activities,
        [stepName]: prev.pipeline_activities[stepName].map((activity, i) =>
          i === index ? { ...activity, schedule: value } : activity
        )
      }
    }))

    if (value === 'Imediatamente') {
      setFormData((prev) => ({
        ...prev,
        pipeline_activities: {
          ...prev.pipeline_activities,
          [stepName]: prev.pipeline_activities[stepName].map((activity, i) =>
            i === index ? { ...activity, interval_multiplier: 0 } : activity
          )
        }
      }))
    }
  }

  const handleScheduleIntervalChange = (stepName, index, event) => {
    const { name, value } = event.target

    setFormData((prev) => ({
      ...prev,
      pipeline_activities: {
        ...prev.pipeline_activities,
        [stepName]: prev.pipeline_activities[stepName].map((activity, i) =>
          i === index ? { ...activity, [name]: parseInt(value, 10) } : activity
        )
      }
    }))
  }

  const handleHourChange = (stepName, index, event) => {
    const { name, value } = event.target

    setFormData((prev) => ({
      ...prev,
      pipeline_activities: {
        ...prev.pipeline_activities,
        [stepName]: prev.pipeline_activities[stepName].map((activity, i) =>
          i === index ? { ...activity, [name]: value } : activity
        )
      }
    }))
  }

  return (
    <Flex flexDirection={'column'}>
      <Flex bg="white" p="15px 20px" borderRadius={'50px'} mb="20px" boxShadow="lg">
        <Text display="flex" fontSize="xl" fontWeight="800" color={textColor}>
          Atividades das etapas do funil
        </Text>
      </Flex>

      {pipelineSteps.map((step, index) => (
        <Card mb="20px" boxShadow="lg" key={index}>
          <Box key={step.name}>
            <Flex mb="10px" flexDirection="row" justifyContent="space-between">
              <Text fontWeight="600" color="gray.800" fontSize={'18px'} pl="0px">
                {step.name}
              </Text>

              <Flex>
                <InputGroup>
                  <Select
                    borderRadius="50px"
                    fontSize="14px"
                    size="md"
                    color="gray.700"
                    width="170px"
                    placeholder="Nova atividade"
                    value={selectedActivity[step.name] || ''}
                    onChange={(event) => handleSelectActivity(step.name, event)}
                  >
                    <option value="Reunião">Reunião</option>
                    <option value="Email">Email</option>
                    <option value="Ligação">Ligação</option>
                    <option value="Mensagem">Mensagem</option>
                    <option value="Tarefa">Tarefa</option>
                  </Select>
                  <InputRightElement>
                    <IconButton
                      aria-label="Adicionar atividade"
                      icon={<FaPlus />}
                      borderRadius="50px"
                      bg="gray.200"
                      size="xs"
                      onClick={() => handleAddActivity(step.name, selectedActivity[step.name])}
                    />
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </Flex>

            {/* Renderizar a lista de atividades adicionadas */}
            <List spacing={5}>
              {formData.pipeline_activities[step.name]?.map((activityObj, index) => (
                <ListItem
                  key={index}
                  fontSize="14px"
                  color="gray.600"
                  display="flex"
                  flexDirection="column"
                  mb="10px"
                  // boxShadow={'sm'}
                  border={'solid 1px #f3f3f3'}
                  p="10px"
                  borderRadius={'10px'}
                >
                  <Flex justifyContent="space-between" mb="5px">
                    <span style={{ fontWeight: '800' }}>{activityObj.activity}</span>
                    <IconButton
                      aria-label="Remover atividade"
                      icon={<FaTimes size={'14px'} />}
                      size="xs"
                      color="red.300"
                      onClick={() => handleRemoveActivity(step.name, index)}
                    />
                  </Flex>
                  {activityObj.activity === 'Email' && (
                    <Flex>
                      <Grid templateColumns="repeat(7, 1fr)" gap="20px" width={'100%'}>
                        <GridItem colSpan={2}>
                          <Flex flexDirection={'column'}>
                            {/* <Textarea
                              mt="5px"
                              placeholder="Adicionar nota"
                              size="sm"
                              value={activityObj.note}
                              onChange={(e) => handleNoteChange(step.name, index, e.target.value)}
                              ref={textareaRefs.current[`${step.name}-${index}`]}
                              mr="20px"
                              minH={'60px'}
                              // rows={3}
                              borderRadius={'10px'}
                            /> */}
                            <Dropdown
                              // value={selectedTemplate[`${step.name}-${index}`] || ''} // Define o valor do Dropdown
                              value={formData.pipeline_activities[step.name]?.[index]?.template_id || ''}
                              onChange={(e) => handleTemplateChange(step.name, index, e)} // Handler atualizado
                              options={templateList}
                              name="channel"
                              optionLabel="name"
                              optionValue="id"
                              display="chip"
                              // maxSelectedLabels={0}
                              placeholder="Selecione um template"
                              style={{
                                fontSize: '12px',
                                width: '100%',
                                margin: '0px 0 10px 0'
                              }}
                              pt={{
                                root: { background: '#fafafa' },
                                input: {
                                  style: {
                                    width: '100%',
                                    background: '#fafafa',
                                    fontSize: '14px'
                                  }
                                },
                                trigger: {
                                  style: {
                                    background: '#fafafa',
                                    fontSize: '14px'
                                  }
                                },
                                item: {
                                  style: {
                                    fontSize: '14px',
                                    padding: '8px 16px',
                                    width: '100%',
                                    background: '#fafafa'
                                  }
                                },
                                panel: {
                                  style: { marginTop: '5px' }
                                }
                              }}
                              required
                            />
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Dropdown
                            value={formData.pipeline_activities[step.name]?.[index]?.schedule || ''}
                            onChange={(e) => handleTemplateScheduleChange(step.name, index, e)} // Handler atualizado
                            options={[
                              { name: 'Imediatamente', value: 'Imediatamente' },
                              { name: 'Agendar', value: 'Agendar' }
                            ]}
                            name="schedule"
                            optionLabel="name"
                            optionValue="value"
                            display="chip"
                            // maxSelectedLabels={1}
                            placeholder="Agendamento"
                            style={{
                              fontSize: '12px',
                              width: '100%'
                            }}
                            pt={{
                              root: { background: '#fafafa' },
                              input: {
                                style: {
                                  width: '100%',
                                  background: '#fafafa',
                                  fontSize: '14px'
                                }
                              },
                              trigger: {
                                style: {
                                  background: '#fafafa',
                                  fontSize: '14px'
                                }
                              },
                              item: {
                                style: {
                                  fontSize: '14px',
                                  padding: '8px 16px',
                                  width: '100%',
                                  background: '#fafafa'
                                }
                              },
                              panel: {
                                style: { marginTop: '5px' }
                              }
                            }}
                            required
                          />
                        </GridItem>
                        <GridItem colSpan="4">
                          <Flex alignItems="center" gap="10px" justifyContent="flex-start">
                            {formData.pipeline_activities[step.name]?.[index]?.schedule === 'Agendar' && (
                              <Flex gap="10px" alignItems={'center'}>
                                <Text>após</Text>
                                <input
                                  type="number"
                                  name="interval_multiplier"
                                  min="0"
                                  max="365"
                                  value={
                                    formData.pipeline_activities[step.name]?.[index]?.interval_multiplier ||
                                    ''
                                  }
                                  onChange={(e) => handleScheduleIntervalChange(step.name, index, e)}
                                  style={{ background: '#fafafa', padding: '10px', borderRadius: '10px' }}
                                />
                                <Dropdown
                                  value={formData.pipeline_activities[step.name]?.[index]?.interval_msec}
                                  onChange={(e) => handleScheduleIntervalChange(step.name, index, e)} // Handler atualizado
                                  options={[
                                    { name: 'minutos', value: 60000 }, // value em miliseconds
                                    { name: 'horas', value: 3600000 },
                                    { name: 'dias', value: 86400000 },
                                    { name: 'semanas', value: 604800000 },
                                    { name: 'meses', value: 2592000000 }
                                  ]}
                                  name="interval_msec"
                                  optionLabel="name"
                                  display="chip"
                                  // maxSelectedLabels={1}
                                  placeholder="Intervalo"
                                  style={{
                                    fontSize: '12px',
                                    width: '130px'
                                  }}
                                  pt={{
                                    root: { background: '#fafafa' },
                                    input: {
                                      style: {
                                        width: '100%',
                                        background: '#fafafa',
                                        fontSize: '14px'
                                      }
                                    },
                                    trigger: {
                                      style: {
                                        background: '#fafafa',
                                        fontSize: '14px'
                                      }
                                    },
                                    item: {
                                      style: {
                                        fontSize: '14px',
                                        padding: '8px 16px',
                                        width: '100%',
                                        background: '#fafafa'
                                      }
                                    },
                                    panel: {
                                      style: { marginTop: '5px' }
                                    }
                                  }}
                                  required
                                />
                              </Flex>
                            )}
                            <Flex gap="10px" alignItems={'center'}>
                              <Text>entre</Text>
                              <input
                                type="time"
                                name="startHour"
                                min="09:00"
                                max="18:00"
                                required
                                value={formData.pipeline_activities[step.name]?.[index]?.startHour}
                                onChange={(e) => handleHourChange(step.name, index, e)}
                                style={{ background: '#fafafa', padding: '10px', borderRadius: '10px' }}
                              />
                              <Text>e</Text>
                              <input
                                type="time"
                                name="endHour"
                                min="09:00"
                                max="18:00"
                                required
                                value={formData.pipeline_activities[step.name]?.[index]?.endHour}
                                onChange={(e) => handleHourChange(step.name, index, e)}
                                style={{ background: '#fafafa', padding: '10px', borderRadius: '10px' }}
                              />
                            </Flex>
                          </Flex>
                        </GridItem>
                      </Grid>
                    </Flex>
                  )}
                  {activityObj.activity === 'Ligação' && (
                    <Flex>
                      <Grid templateColumns="repeat(7, 1fr)" gap="20px">
                        <GridItem colSpan={4}>
                          <Flex flexDirection={'column'}>
                            <Textarea
                              mt="5px"
                              placeholder="Adicionar nota"
                              size="sm"
                              value={activityObj.note}
                              onChange={(e) => handleNoteChange(step.name, index, e.target.value)}
                              ref={textareaRefs.current[`${step.name}-${index}`]}
                              mr="20px"
                              minH={'90px'}
                              // rows={3}
                              borderRadius={'10px'}
                            />
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={3}>
                          <Flex wrap={'wrap'} gap="5px" pt="10px">
                            <AddTermToInput
                              textToCopy="{{NomeContato}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{NomeEmpresa}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{NomeVendedor}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{Segmento}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{Cidade}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{o/a}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{ele/ela}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy={`${accountName}`}
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                          </Flex>
                        </GridItem>
                      </Grid>
                    </Flex>
                  )}
                  {activityObj.activity === 'Tarefa' && (
                    <Flex>
                      <Grid templateColumns="repeat(7, 1fr)" gap="20px">
                        <GridItem colSpan={4}>
                          <Flex flexDirection={'column'}>
                            <Textarea
                              mt="5px"
                              placeholder="Adicionar nota"
                              size="sm"
                              value={activityObj.note}
                              onChange={(e) => handleNoteChange(step.name, index, e.target.value)}
                              ref={textareaRefs.current[`${step.name}-${index}`]}
                              mr="20px"
                              minH={'90px'}
                              // rows={3}
                              borderRadius={'10px'}
                            />
                          </Flex>
                        </GridItem>
                        <GridItem colSpan={3}>
                          <Flex wrap={'wrap'} gap="5px" pt="10px">
                            <AddTermToInput
                              textToCopy="{{NomeContato}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{NomeEmpresa}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{NomeVendedor}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{Segmento}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{Cidade}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{o/a}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy="{{ele/ela}}"
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                            <AddTermToInput
                              textToCopy={`${accountName}`}
                              textareaRef={textareaRefs.current[`${step.name}-${index}`]}
                            />
                          </Flex>
                        </GridItem>
                      </Grid>
                    </Flex>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        </Card>
      ))}
    </Flex>
  )
}
