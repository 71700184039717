import { Flex, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { MdBarChart } from 'react-icons/md'

export function SalesInteligence({ accountData }) {
  const textColor = useColorModeValue('navy.700', 'white')
  return (
    <Card h="fit-content">
      <Flex alignItems="center" w="100%" mb="30px">
        <Icon as={MdBarChart} width="20px" height="20px" mr={3} color="inherit" />

        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Sales Inteligence
        </Text>
      </Flex>
      {accountData && accountData?.business_data?.BIKey && (
        <Link href={`https://lookerstudio.google.com/s/${accountData.business_data.BIKey}`} target="_blank">
          <Text
            background="#49bfff"
            style={{
              fontWeight: '500',
              padding: '10px 20px',
              color: 'white',
              borderRadius: '50px',
              width: 'fit-content'
            }}
            _hover={{
              background: '#3a9dd6'
            }}
          >
            Acessar relatório
          </Text>
        </Link>
      )}
    </Card>
  )
}
