import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
// import { useParams } from 'react-router-dom';
import apiDados from '../../../api/api-dados'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Select,
  Stack,
  Switch,
  useColorModeValue,
  useToast,
  Text
} from '@chakra-ui/react'
import { PhoneIcon, SearchIcon } from '@chakra-ui/icons'
import Card from '../../../components/card/Card'
// import { NotAllowedIcon } from '@chakra-ui/icons';
// import formatDate from 'hooks/formatDate';
import TableConsulta from './components/tableConsulta'
import ModalConsultaCnpj from './components/modalProspeccao'
import {
  cnaes,
  naturezasJuridicas,
  situacaoCadastral,
  estadosUF,
  listaMunicipios
} from 'contexts/dadosBrasileiros'
import { MultiSelect } from 'primereact/multiselect'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Chips } from 'primereact/chips'
import { Toast } from 'primereact/toast'
import { GoogleSearchApi } from './components/googlesearchapicomponent'
import { ConsultaCNPJRF } from './components/consultaCNPJRF'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'

export default function Settings() {
  const textColor = useColorModeValue('navy.700', 'white')

  const accountId = getUserAccountId()

  let capital_social_lte = null
  let capital_social_gte = null
  let data_abertura_lte = null
  let data_abertura_gte = null

  const [cnpj, setCnpj] = useState([])
  const [trigger, setTrigger] = useState(false)
  const [entriesFound, setEntriesFound] = useState()

  const [formData, setFormData] = useState({
    query: {
      atividade_principal: [],
      bairro: [],
      cep: [],
      ddd: [],
      municipio: [],
      natureza_juridica: ['2062'],
      situacao_cadastral: 'ATIVA',
      termo: [],
      uf: []
    },
    page: 0,
    extras: {
      com_contato_telefonico: true,
      com_email: true,
      excluir_mei: true,
      incluir_atividade_secundaria: false,
      somente_celular: false,
      somente_filial: false,
      somente_fixo: false,
      somente_matriz: false,
      somente_mei: false
    },
    range_query: {
      capital_social: { lte: capital_social_lte, gte: capital_social_gte },
      data_abertura: { lte: data_abertura_lte, gte: data_abertura_gte }
    }
  })

  const [resultado, setResultado] = useState([])
  const [loading, setLoading] = useState(false)
  const [sendAlert, setSendAlert] = useState('')

  const cnaeMultiselectLabelTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{`(${option.value}) ${option.name}`}</div>
      </div>
    )
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
    console.log(`Name: ${name}, value: ${value}`)
  }

  const handleQueryChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      query: {
        ...prevFormData.query,
        [name]: value
      }
    }))
    console.log(`Query Name: ${name}, value: ${value}`)
    console.log(`formData.query: ${JSON.stringify(formData?.query)}`)
  }

  const handleCnpjConsultaChange = (event) => {
    const { value } = event.target
    setCnpj(value)
    console.log(`Query Name: ${event.name}, value: ${value}`)
  }

  const handleExtraChange = (event) => {
    const { name, checked } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      extras: {
        ...prevFormData.extras,
        [name]: checked
      }
    }))
    console.log(`Extra Name: ${name}, value: ${checked}`)
    console.log(`event.taget: ${JSON.stringify(checked)}`)
  }

  const handleLoadMore = async () => {
    setLoading(true)
    const { page: currentPage } = formData
    console.log('Página original:', currentPage)
    setFormData((prevState) => ({ ...prevState, page: currentPage + 1 }))
    console.log('Página nova:', formData?.page)

    try {
      const {
        data: {
          data: { cnpj }
        }
      } = await apiDados.post('/cnpj/search', formData)

      const cnpjsUnicos = new Set(resultado.map((item) => item.cnpj))
      const novosCnpjs = cnpj.filter((item) => !cnpjsUnicos.has(item.cnpj))
      setResultado((prevData) => [...prevData, ...novosCnpjs])

      console.log('Sucesso!', novosCnpjs)
      console.log('Novo formData:', formData)
    } catch (error) {
      setSendAlert('error')
      console.error('Erro ao carregar mais dados:', error.response)
    }
    setLoading(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const {
        data: {
          data,
          count,
          success,
          page,
          data: { cnpj }
        }
      } = await apiDados.post('/cnpj/search', formData)
      console.log('Sucesso!', cnpj)
      console.log('count!', data.count)
      setEntriesFound(data.count)
      setResultado(cnpj)
      setSendAlert('success')
      console.log('Sucesso! Page:', page)
      console.log('Sucesso! Sucess: ', success)
      console.log('Sucesso! Data:', data)
      console.log('Sucesso! formData:', formData)
    } catch (error) {
      setSendAlert('error')
      console.error('Ocorreu um erro ao enviar os dados:', error.response)
    }
    // console.log(`Resultado: ${JSON.stringify(resultado)}`);
    // console.log(`formData: ${JSON.stringify(formData)}`);
  }

  async function saveList() {
    const listData = {
      account_id: accountId,
      name: 'lista 1',
      data: JSON.stringify(resultado)
    }
    try {
      const response = await axios.post(`/companies-list`, listData, { headers: authHeader() })
      console.log('Lista salva com sucesso', response)
      toastSuccess('Lista salva.')
    } catch (error) {
      console.log('Não foi possível salvar a lista', error)
      toastError('Erro ao salvar a lista.')
    }
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  const { termo, atividade_principal, natureza_juridica, situacao_cadastral, uf, municipio, cep } =
    formData?.query

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid height="0px" templateRows="repeat(2, 1fr)" templateColumns="repeat(6, 1fr)" gap={5}>
        <GridItem rowSpan={2} colSpan={2}>
          <ConsultaCNPJRF loadData={setResultado} />
        </GridItem>
        <GridItem colSpan={4}>
          <Box>
            <Flex>
              <Flex>
                <Button
                  colorScheme="blue"
                  background={'#49BFFF'}
                  borderRadius={'50px'}
                  onClick={''}
                  disabled={loading}
                  mr="10px"
                >
                  Suas listas
                </Button>
              </Flex>
              <Flex>
                {resultado && resultado.length > 0 && (
                  <Button
                    variant="brand"
                    colorScheme="blue"
                    borderRadius={'50px'}
                    onClick={saveList}
                    // disabled={loading}
                    cursor={'pointer'}
                  >
                    Salvar lista
                  </Button>
                )}
              </Flex>
            </Flex>
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          {resultado && resultado.length > 0 && (
            <Card>
              <Stack>
                <Text fontSize={'18px'} fontWeight={'800'} mb="20px">
                  {entriesFound} Empresas encontradas
                </Text>
                <TableConsulta tableData={resultado || []} />
              </Stack>

              <Button
                colorScheme="blue"
                background={'#49BFFF'}
                borderRadius="20px"
                onClick={handleLoadMore}
                disabled={loading}
              >
                Carregar Mais
              </Button>
            </Card>
          )}
        </GridItem>
      </Grid>
      {/* <SimpleGrid mb="20px" columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        {resultado && resultado.length > 0 && (
          <Card>
            <Stack>
              <Text fontSize={'18px'} fontWeight={'800'} mb="20px">
                {entriesFound} Empresas encontradas
              </Text>
              <TableConsulta tableData={resultado || []} />
            </Stack>

            <Button onClick={handleLoadMore} disabled={loading}>
              Carregar Mais
            </Button>
          </Card>
        )}
      </SimpleGrid> */}
      {/* <GoogleSearchApi /> //Colocar pra rodar */}
      <Toast position="bottom-center" ref={toast} />
    </Box>
  )
}
