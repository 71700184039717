// Custom components
import Card from 'components/card/Card.js'
import Menu from 'components/menu/MainMenu'
import IconBox from 'components/icons/IconBox'
import WaitingLine from './WaitingLine'

import {
  Avatar,
  Box,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { MdDone } from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { logout, getUserAuthentication } from 'hooks/authService'

export default function Conversion(props) {
  const { ...rest } = props

  const { user } = getUserAuthentication()

  const whatchedData = [
    {
      id: 514,
      name: 'Pedro M.',
      age: '38 anos',
      status: 'Online',
      city: 'Porto Alegre, RS',
      special_conditions: ['Perdas significativas'],
      flag: 'red'
    },
    {
      id: 524,
      name: 'Maria D.',
      age: '27 anos',
      status: 'Online',
      city: 'Porto Alegre, RS',
      special_conditions: ['Gravidez'],
      flag: 'yellow'
    },
    {
      id: 54,
      name: 'Thiago B.',
      age: '52 anos',
      status: 'Online',
      city: 'Canoas, RS',
      special_conditions: ['Medicação regular'],
      flag: 'green'
    }
  ]

  // Chakra Color Mode
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const boxBg = useColorModeValue('secondaryGray.300', 'navy.700')
  const brandColor = useColorModeValue('brand.500', 'brand.400')

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest} height="fit-content">
      <Flex alignItems="center" w="100%" mb="30px">
        <Icon as={MdDone} width="20px" height="20px" mr={3} color="inherit" />
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          {`Sala de espera`}
        </Text>
        <Menu ms="auto" />
      </Flex>
      <Flex flexDirection="column">
        {whatchedData.length > 0 &&
          whatchedData.map((watched) => (
            <WaitingLine boxShadow={cardShadow} watchedData={watched} volunteer={user} />
          ))}
      </Flex>
      <Box px="11px" overflowY="scroll" maxHeight="455px"></Box>
    </Card>
  )
}
