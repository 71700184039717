import React, { useState, useEffect } from 'react'
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'

import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import { FaWhatsapp, FaPlus, FaEdit } from 'react-icons/fa'

// import DealForm from '../../formDeal/components/dealForm'
import DealCard from './dealCard'
import NewStepDeal from './newStepDeal'

export default function Step({ name, objective, stepDeals, index, stepId, pipelineId, onUpdate, stepsList }) {
  const textColor = useColorModeValue('navy.700', 'white')
  const account_id = getUserAccountId()

  console.log('STEPDEALS:', stepDeals)

  // const focusInicial = React.useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [dealsList, setDealsList] = useState([])
  const [totalDeals, setTotalDeals] = useState()

  // useEffect(() => {
  //   if (account_id && name) {
  //     // getDeals(account_id, stepId)
  //   }
  // }, [account_id, stepId, name])

  async function updateStepName(newName) {
    try {
      await axios.patch(`/steps/${stepId}`, { name: newName }, { headers: authHeader() })
      onUpdate()
    } catch (error) {
      console.log('Erro ao atualizar o nome da etapa:', error)
    }
  }

  async function updateObjective(newObjective) {
    try {
      await axios.patch(`/steps/${stepId}`, { objective: newObjective }, { headers: authHeader() })
      onUpdate()
    } catch (error) {
      console.log('Erro ao atualizar o objetivo da etapa:', error)
    }
  }

  return (
    <>
      <Card
        maxWidth="300px"
        height="70vh"
        p="20px 10px"
        draggable
        style={{
          opacity: 0,
          animation: 'fadeIn 0.1s ease-in forwards',
          animationDelay: `${index * 0.03}s`, // Delay de 0.5s entre cada card
          background:
            'linear-gradient(to bottom, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0) 100%)'
        }}
      >
        <style>
          {`
      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }
    `}
        </style>
        <Flex
          justifyContent={'space-between'}
          minWidth={'170px'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Flex alignItems={'center'} mb="-10px">
            <Editable
              defaultValue={name}
              fontSize="md"
              fontWeight="800"
              color={textColor}
              onSubmit={(e) => updateStepName(e)}
              // onMouseOver={}
            >
              <EditableInput />
              <EditablePreview />
            </Editable>
            <Text fontSize="13px" fontWeight="800" ml="5px">
              {totalDeals && `(${totalDeals})`}
            </Text>
          </Flex>
          <Editable
            defaultValue={objective}
            fontSize="xs"
            fontWeight="500"
            color={'gray.400'}
            mb="15px"
            onSubmit={(e) => updateObjective(e)}
            textAlign={'center'}
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
          {/* <Flex
            justifyContent={'center'}
            onClick={() => onOpen()}
            cursor="pointer"
            p="5px 15px"
            background="gray.200"
            borderRadius={'50px'}
            mb="15px"
          >
            <FaPlus size={'10px'} color={'gray'} p="5px 10px" />
          </Flex> */}
          <NewStepDeal
            stepId={stepId}
            pipelineId={parseInt(pipelineId, 10)}
            stepName={name}
            onOpen={onOpen}
            isOpen={isOpen}
            onClose={onClose}
            z-1
          />
        </Flex>
        <Flex flexDirection={'column'} gap="10px">
          {/* {dealsList && dealsList.map((deal, index) => <DealCard deal={deal} key={deal.id} index={index} />)} */}
          {stepDeals &&
            stepDeals.map((deal, index) => (
              <DealCard deal={deal} key={deal.id} index={index} stepsList={stepsList} />
            ))}
        </Flex>
      </Card>
    </>
  )
}
