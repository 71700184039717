// import React, { useState, useEffect, forwardRef } from 'react'
// import { Button, useToast } from '@chakra-ui/react'

// const AddTermToInput = forwardRef(({ textToCopy, textareaRef }) => {
//   const toast = useToast()

//   const handleClick = async () => {
//     try {
//       await navigator.clipboard.writeText(textToCopy)

//       // Usa a API do React para atualizar o textarea
//       textareaRef.current.focus()
//       document.execCommand('insertText', false, textToCopy)

//       toast({
//         title: 'Texto copiado e colado com sucesso!',
//         status: 'success',
//         duration: 3000,
//         isClosable: true
//       })
//     } catch (err) {
//       toast({
//         title: 'Falha ao copiar/colar o texto.',
//         description: err.message,
//         status: 'error',
//         duration: 5000,
//         isClosable: true
//       })
//     }
//   }

//   return (
//     <Button
//       onClick={handleClick}
//       mt={'8px'}
//       mb={4}
//       fontSize={'xs'}
//       borderRadius={'50px'}
//       p="5px 10px"
//       bg="#F4F7FE"
//       mr="5px"
//       h={'30px'}
//     >
//       {textToCopy}
//     </Button>
//   )
// })

// export default AddTermToInput

import React, { forwardRef } from 'react'
import { Button, useToast } from '@chakra-ui/react'

const AddTermToInput = forwardRef(({ textToCopy, textareaRef }, ref) => {
  const toast = useToast()

  const handleClick = async () => {
    try {
      // Copiar o texto para o clipboard
      await navigator.clipboard.writeText(textToCopy)

      // Verifica se o textareaRef está definido
      if (textareaRef && textareaRef.current) {
        const textarea = textareaRef.current
        const cursorPosition = textarea.selectionStart

        // Atualiza o valor do textarea e move o cursor
        const newValue =
          textarea.value.slice(0, cursorPosition) + textToCopy + textarea.value.slice(cursorPosition)
        textarea.value = newValue

        // Reposiciona o cursor após o texto inserido
        textarea.setSelectionRange(cursorPosition + textToCopy.length, cursorPosition + textToCopy.length)

        // Foca novamente no textarea
        textarea.focus()

        toast({
          title: 'Texto copiado e colado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      } else {
        throw new Error('Textarea não está disponível.')
      }
    } catch (err) {
      toast({
        title: 'Falha ao copiar/colar o texto.',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Button
      onClick={handleClick}
      fontSize="10px"
      fontWeight={'500'}
      borderRadius="50px"
      px="10px"
      bg="#F4F7FE"
      h="26px"
    >
      {textToCopy}
    </Button>
  )
})

export default AddTermToInput
