// import React, { useState, useEffect } from 'react'
// import { Text, useStyles } from '@chakra-ui/react'
// import feathers from '@feathersjs/feathers'
// import socketio from '@feathersjs/socketio-client'
// import io from 'socket.io-client'
// import Peer from 'peerjs'

// export default function Telemedicine() {
//   const [roomId, setRoomId] = useState(null)

//   const socket = io('http://localhost:3034')
//   const app = feathers()

//   const video = { width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }

//   const ROOM_ID = '<%= roomId %>'

//   useEffect(() => {
//     app.configure(socketio(socket))
//   }, [socket])

//   const videoGrid = document.getElementById('video-grid')
//   const myPeer = new Peer(undefined, {
//     host: '/',
//     port: '3450'
//   })
//   const myVideo = document.createElement('video')
//   myVideo.muted = true
//   const peers = {}
//   navigator.mediaDevices
//     .getUserMedia({
//       video: true,
//       audio: true
//     })
//     .then((stream) => {
//       addVideoStream(myVideo, stream)

//       myPeer.on('call', (call) => {
//         call.answer(stream)
//         const video = document.createElement('video')
//         call.on('stream', (userVideoStream) => {
//           addVideoStream(video, userVideoStream)
//         })
//       })

//       socket.on('user-connected', (userId) => {
//         connectToNewUser(userId, stream)
//       })
//     })

//   socket.on('user-disconnected', (userId) => {
//     if (peers[userId]) peers[userId].close()
//   })

//   myPeer.on('open', (id) => {
//     socket.emit('join-room', ROOM_ID, id)
//   })

//   function connectToNewUser(userId, stream) {
//     const call = myPeer.call(userId, stream)
//     const video = document.createElement('video')
//     call.on('stream', (userVideoStream) => {
//       addVideoStream(video, userVideoStream)
//     })
//     call.on('close', () => {
//       video.remove()
//     })

//     peers[userId] = call
//   }

//   function addVideoStream(video, stream) {
//     video.srcObject = stream
//     video.addEventListener('loadedmetadata', () => {
//       video.play()
//     })
//     videoGrid.append(video)
//   }

//   return <div id="video-grid"></div>
// }

// ################ VERSÃO 2
// import React, { useState, useEffect, useRef } from 'react'
// import { Box, Button, Flex, HStack } from '@chakra-ui/react'
// import feathers from '@feathersjs/feathers'
// import socketio from '@feathersjs/socketio-client'
// import io from 'socket.io-client'
// import Peer from 'peerjs'

// export default function Telemedicine() {
//   const [roomId, setRoomId] = useState('<%= roomId %>')
//   const videoGridRef = useRef(null)
//   const socket = io('http://localhost:3034')
//   const app = feathers()
//   const myPeer = new Peer(undefined, {
//     host: '/',
//     port: '3450'
//   })
//   const peers = {}

//   const [isCameraEnabled, setIsCameraEnabled] = useState(true)
//   const [isMicEnabled, setIsMicEnabled] = useState(true)
//   let myStream
//   const [showVideo, setShowvideo] = useState(true)

//   useEffect(() => {
//     app.configure(socketio(socket))

//     const myVideo = document.createElement('video')
//     myVideo.muted = true
//     myVideo.controls = true
//     myVideo.style.borderRadius = '20px'

//     navigator.mediaDevices
//       .getUserMedia({
//         video: true,
//         audio: true
//       })
//       .then((stream) => {
//         myStream = stream
//         addVideoStream(myVideo, stream)

//         myPeer.on('call', (call) => {
//           call.answer(stream)
//           const video = document.createElement('video')
//           call.on('stream', (userVideoStream) => {
//             addVideoStream(video, userVideoStream)
//           })
//         })

//         socket.on('user-connected', (userId) => {
//           connectToNewUser(userId, stream)
//         })
//       })

//     socket.on('user-disconnected', (userId) => {
//       if (peers[userId]) peers[userId].close()
//     })

//     myPeer.on('open', (id) => {
//       socket.emit('join-room', roomId, id)
//     })

//     if (myStream) {
//       myStream.getTracks().forEach((track) => track.stop())
//     }

//     return () => {
//       socket.disconnect()
//       myPeer.disconnect()
//     }
//   }, [socket, roomId])

//   const connectToNewUser = (userId, stream) => {
//     const call = myPeer.call(userId, stream)
//     const video = document.createElement('video')
//     call.on('stream', (userVideoStream) => {
//       addVideoStream(video, userVideoStream)
//     })
//     call.on('close', () => {
//       video.remove()
//     })

//     peers[userId] = call
//   }

//   const addVideoStream = (video, stream) => {
//     video.srcObject = stream
//     video.addEventListener('loadedmetadata', () => {
//       video.play()
//     })
//     if (videoGridRef.current) {
//       videoGridRef.current.append(video)
//     }
//   }

//   const toggleCamera = () => {
//     setIsCameraEnabled(!isCameraEnabled)
//     if (myStream) {
//       myStream.getVideoTracks().forEach((track) => (track.enabled = !track.enabled))
//     }
//   }

//   const toggleMic = () => {
//     setIsMicEnabled(!isMicEnabled)
//     if (myStream) {
//       myStream.getAudioTracks().forEach((track) => (track.enabled = !track.enabled))
//     }
//   }

//   return (
//     <Flex>
//       {/* <Box ref={videoGridRef} id="video-grid" width="100%" height="100%" /> */}
//       <HStack spacing={4} mb={4}>
//         {' '}
//         {/* Adicione os botões em um HStack */}
//         <Button onClick={toggleCamera} colorScheme={isCameraEnabled ? 'blue' : 'gray'}>
//           {isCameraEnabled ? 'Desabilitar Câmera' : 'Habilitar Câmera'}
//         </Button>
//         <Button onClick={toggleMic} colorScheme={isMicEnabled ? 'blue' : 'gray'}>
//           {isMicEnabled ? 'Desabilitar Microfone' : 'Habilitar Microfone'}
//         </Button>
//       </HStack>
//       <Box ref={videoGridRef} id="video-grid" width="100%" height="100%" />
//     </Flex>
//   )
// }

import React, { useState, useEffect, useRef } from 'react'
import { Box, Button, Flex, HStack, IconButton } from '@chakra-ui/react'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import Peer from 'peerjs'
import { FaVideo, FaVideoSlash, FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa'

export default function Telemedicine() {
  const [roomId, setRoomId] = useState('<%= roomId %>')
  const videoGridRef = useRef(null)
  const myVideoRef = useRef(null) // Referência para o vídeo local
  const socket = io('http://localhost:3034')
  const app = feathers()
  const myPeer = new Peer(undefined, {
    host: '/',
    port: '3450'
  })
  const peers = {}

  const [isCameraEnabled, setIsCameraEnabled] = useState(true)
  const [isMicEnabled, setIsMicEnabled] = useState(true)
  let myStream = useRef(null)

  useEffect(() => {
    app.configure(socketio(socket))

    const myVideo = myVideoRef.current || document.createElement('video')
    myVideo.muted = true
    myVideo.controls = true
    myVideo.style.borderRadius = '20px'

    if (!myVideoRef.current) {
      // Apenas adicione o vídeo ao DOM uma vez
      myVideoRef.current = myVideo
    }

    startMediaStream()

    socket.on('user-disconnected', (userId) => {
      if (peers[userId]) peers[userId].close()
    })

    myPeer.on('open', (id) => {
      socket.emit('join-room', roomId, id)
    })

    return () => {
      socket.disconnect()
      myPeer.disconnect()
      if (myStream.current) {
        myStream.current.getTracks().forEach((track) => track.stop())
      }
    }
  }, [socket, roomId])

  const startMediaStream = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true
      })
      .then((stream) => {
        myStream.current = stream
        addVideoStream(myVideoRef.current, stream)

        myPeer.on('call', (call) => {
          call.answer(stream)
          const video = document.createElement('video')
          call.on('stream', (userVideoStream) => {
            addVideoStream(video, userVideoStream)
          })
        })

        socket.on('user-connected', (userId) => {
          connectToNewUser(userId, stream)
        })
      })
  }

  const connectToNewUser = (userId, stream) => {
    const call = myPeer.call(userId, stream)
    const video = document.createElement('video')
    call.on('stream', (userVideoStream) => {
      addVideoStream(video, userVideoStream)
    })
    call.on('close', () => {
      video.remove()
    })

    peers[userId] = call
  }

  const addVideoStream = (video, stream) => {
    video.srcObject = stream
    video.addEventListener('loadedmetadata', () => {
      video.play()
    })
    if (!videoGridRef.current.contains(video)) {
      videoGridRef.current.append(video)
    }
  }

  const toggleCamera = () => {
    setIsCameraEnabled(!isCameraEnabled)
    if (myStream.current) {
      const videoTrack = myStream.current.getVideoTracks()[0]
      if (videoTrack) {
        if (isCameraEnabled) {
          // Para a câmera fisicamente
          videoTrack.stop()
        } else {
          // Reinicia a câmera quando habilitar
          navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
            const newVideoTrack = stream.getVideoTracks()[0]
            myStream.current.removeTrack(videoTrack)
            myStream.current.addTrack(newVideoTrack)
          })
        }
      }
    }
  }

  const toggleMic = () => {
    setIsMicEnabled(!isMicEnabled)
    if (myStream.current) {
      myStream.current.getAudioTracks().forEach((track) => (track.enabled = !track.enabled))
    }
  }

  return (
    <Flex direction="column" align="center" justify="center">
      <Box
        ref={videoGridRef}
        id="video-grid"
        width="100%"
        height="100%"
        mb="20px"
        boxShadow={'2xl'}
        borderRadius={'20px'}
      />
      <Flex gap={'10px'} mb={4} style={{ position: 'relative', top: '-75px' }}>
        <IconButton
          onClick={toggleCamera}
          fontSize={'14px'}
          fontWeight={'500'}
          colorScheme={isCameraEnabled ? 'blue' : 'gray'}
          background={isCameraEnabled ? '#49BFFF' : 'white'}
          borderRadius={'20px'}
          isRound={true}
          icon={isCameraEnabled ? <FaVideo /> : <FaVideoSlash />}
        />
        <IconButton
          onClick={toggleMic}
          background={isMicEnabled ? '#49BFFF' : 'white'}
          fontSize={'14px'}
          fontWeight={'500'}
          colorScheme={isMicEnabled ? 'blue' : 'gray'}
          borderRadius={'20px'}
          isRound={true}
          icon={isMicEnabled ? <FaMicrophone /> : <FaMicrophoneSlash />}
        />
      </Flex>
    </Flex>
  )
}
