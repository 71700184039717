import { useState, useEffect } from 'react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader' // Função authHeader
import { getUserAccountId } from 'hooks/authService'

function useGetAccountCompanies() {
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const account_id = getUserAccountId()

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await axios.get(`/companies?account_id=${account_id}`, {
          headers: authHeader()
        })
        setCompanyList(response.data.data)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    if (account_id) {
      fetchCompanies()
    }
  }, [account_id])

  return { companyList, loading, error }
}

export default useGetAccountCompanies
