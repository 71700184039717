// Chakra imports
import { Avatar, Box, Icon, Link, SimpleGrid, Text, useColorModeValue, Flex } from '@chakra-ui/react'

// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar'
import Tasks from './components/Tasks'
import MiniStatistics from 'components/card/MiniStatistics'
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import { MdBarChart } from 'react-icons/md'
import { logout, getUserAuthentication, getUserAuthenticationRole, getUserAccount } from 'hooks/authService'
import ActivityItem from './components/ActivityItem'
import TelemedicineRoom from './components/TelemedicineRoom'
import authHeader from 'hooks/authHeader'
import NewAccountForm from '../../admin/formAccount'
import axios from 'api/axios'
import Dashboard from 'layouts/admin'
import LastApproachs from './components/LastApproachs'
import RegistroLigacoes from '../calls/'
import ClientsDash from './components/ClientsDash'
import { Redirect } from 'react-router-dom'
import Card from 'components/card/Card'
import { DailyPills } from './components/DailyPills'
import { SalesInteligence } from './components/salesInteligence'

export default function AdminDefault() {
  const userData = getUserAuthentication()
  const [accountData, setAccountData] = useState({})
  let userRole, main_account_id

  if (userData) {
    userRole = userData?.user?.role
    main_account_id = userData?.user?.user_data?.main_account_id
  }

  useEffect(() => {
    async function getUserAccount() {
      try {
        const response = await axios.get(`/accounts/${main_account_id}`, { headers: authHeader() })
        setAccountData(response.data)
      } catch (error) {
        console.error('Crie uma nova conta.', error)
      }
    }
    getUserAccount()
  }, [])

  useEffect(() => {
    console.log('Conta autenticada:', accountData)
  }, [accountData])

  return (
    <Box pt={{ base: '50px', md: '80px', xl: '80px' }}>
      {accountData && accountData.status === 'active' && (
        <SimpleGrid rows={2} gap="20px">
          <SimpleGrid columns={{ sm: 1, xl: 2 }} gap="20px">
            <Flex flexDirection={'column'} gap="20px">
              <ClientsDash />
              <LastApproachs />
              <RegistroLigacoes type="module" />
            </Flex>
            <SimpleGrid columns={2} gap="20px">
              <Flex flexDirection={'column'} gap="20px">
                <MiniCalendar h="100%" minW="100%" selectRange={false} />
              </Flex>
              <Flex flexDirection={'column'} gap="20px">
                <DailyPills />
                <SalesInteligence accountData={accountData} />
              </Flex>
              <Tasks accountId={main_account_id} />
            </SimpleGrid>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px">
            <Text>Suporte</Text>
            <Text>Sugestões</Text>
            <Text>Anúncios representantes</Text>
            <Text>Comunicação Otten Sales: Mídias sociais, contrate um atendente</Text>
          </SimpleGrid>
        </SimpleGrid>
      )}
    </Box>
  )
}
