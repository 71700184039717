import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
import {
  Button,
  Flex,
  FormControl,
  Icon,
  Input,
  Select,
  Text,
  Textarea,
  SimpleGrid,
  IconButton,
  HStack
} from '@chakra-ui/react'
import authHeader from 'hooks/authHeader'
import { getUserAccountId, getUserAuthentication } from 'hooks/authService'
import { ptBR } from 'contexts/dadosBrasileiros'
import { FaFlag } from 'react-icons/fa'
import { addLocale } from 'primereact/api'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
// import { Button } from 'primereact/button'
import { cnaes, porteEmpresas, naturezasJuridicas, estadosRegioes } from 'contexts/dadosBrasileiros'
import { Slider } from 'primereact/slider'
import { Toast } from 'primereact/toast'
// import { PriorityComponent } from './priorityComponent'
import { SpeedDial } from 'primereact/speeddial'
import { Tooltip } from 'primereact/tooltip'

export default function FormActivities({ entity, entityId, refreshComponent }) {
  const account_id = getUserAccountId()
  const {
    user: { id: userId }
  } = getUserAuthentication()
  // console.log('userData', userId)

  const activityId = null // puxar activityId de params

  addLocale('ptBR', {
    irstDayOfWeek: 1,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  })

  const tomorrow9am = getNextBusinessDayAt9AM()
  const [deadLine, setDeadLine] = useState(tomorrow9am)

  const [activity, setActivity] = useState({
    owner_id: userId && userId,
    account_id: account_id && account_id,
    entity: entity && entity,
    entity_id: entityId && entityId,
    type: 'call',
    name: 'atividade entidade status',
    description: '',
    status: 'todo',
    coments: [],
    start_date: null,
    dead_line: null,
    flag: '',
    activity_data: {}
  })

  useEffect(() => {
    console.log('activityState: ', activity)
  }, [activity])

  async function handleChange(event) {
    const { name, value } = event.target
    setActivity((prevActivity) => ({
      ...prevActivity,
      [name]: value
    }))

    if (name === 'type') {
      setActivity((prevActivity) => ({
        ...prevActivity,
        name: value
      }))
    }
  }

  async function handleChangeDate(event) {
    const { value } = event.target
    const timestamp_dead_line = convertToTimestamp(value)

    setDeadLine(value)
    setActivity((prevActivity) => ({
      ...prevActivity,
      dead_line: timestamp_dead_line
    }))
  }

  async function handleSubmit(event) {
    event.preventDefault()

    try {
      const response = await axios.post('/activities', activity, { headers: authHeader() })
      // console.log('activity response: ', response)
      refreshComponent(response)
      setActivity((prevActivity) => ({
        ...prevActivity,
        type: 'call',
        name: 'atividade entidade status',
        description: '',
        status: 'todo',
        coments: [],
        start_date: null,
        dead_line: null
      }))
      toastSuccess('Atividade adicionada.')
    } catch (error) {
      console.log('error', error)
      toastError('Erro ao adicionar atividade')
    }
    return
  }

  function convertToTimestamp(localDateTime) {
    return new Date(localDateTime).getTime() / 1000
  }
  function renderLocalDateTime(timestamp) {
    return new Date(timestamp).toLocaleString()
  }

  function getNextBusinessDayAt9AM() {
    const date = new Date()

    date.setHours(9, 0, 0, 0)

    let dayOfWeek = date.getDay()

    if (dayOfWeek === 6) {
      date.setDate(date.getDate() + 2)
    } else if (dayOfWeek === 0) {
      date.setDate(date.getDate() + 1)
    } else {
      date.setDate(date.getDate() + 1)

      dayOfWeek = date.getDay()
      if (dayOfWeek === 6) {
        date.setDate(date.getDate() + 2)
      } else if (dayOfWeek === 0) {
        date.setDate(date.getDate() + 1)
      }
    }

    return date
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  const [showPriorities, setShowPriorities] = useState(false)
  const [activityPriority, setActivityPriority] = useState({
    value: 'Prioridade normal',
    flag: 'normal',
    color: '#e9e9e9'
  })

  const prioritiesList = [
    {
      value: 'Baixa prioridade',
      flag: 'low',
      color: '#49BFFF'
    },
    {
      value: 'Prioridade normal',
      flag: 'normal',
      color: '#e9e9e9'
    },
    {
      value: 'Alta prioridade',
      flag: 'high',
      color: '#F6E05E'
    },
    {
      value: 'Máxima prioridade ',
      flag: 'top',
      color: '#E53E3E'
    }
  ]

  return (
    <Flex bg="white" borderRadius="20px" p="20px" width={'100%'}>
      <form onSubmit={handleSubmit}>
        <Flex flexDirection="column" w="full">
          <Flex flexDirection={'row'} alignItems={'flex-start'} width={'100%'}>
            <Text fontSize="18px" fontWeight="800" mb="24px" mr="20px">
              Atividade
            </Text>
            <SelectButton
              name="type"
              value={activity.type}
              onChange={handleChange}
              options={[
                { value: 'call', name: 'Ligação' },
                { value: 'task', name: 'Tarefa' },
                { value: 'email', name: 'Email' },
                { value: 'message', name: 'Mensagem' },
                { value: 'meeting', name: 'Reunião' },
                { value: 'visit', name: 'Visita' }
              ]}
              optionLabel="name"
              style={{
                fontSize: '12px',
                fontWeight: '500',

                margin: '0 20px 10px 0'
              }}
              pt={{
                button: {
                  style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
                },
                label: { style: { fontSize: '12px', fontWeight: '500' } }
              }}
            />
          </Flex>
          <Flex alignItems={'center'}>
            <Calendar
              value={deadLine}
              onChange={handleChangeDate}
              locale="ptBR"
              showIcon
              iconPos="left"
              showTime
              dateFormat="D, dd/M/yy"
              hourFormat="24"
              variant="outlined"
              pt={{
                root: {
                  style: {
                    fontSize: '14px',
                    margin: '0px',
                    width: '220px'
                  }
                }
              }}
            />
            <HStack onMouseOver={() => setShowPriorities(true)} onMouseLeave={() => setShowPriorities(false)}>
              <IconButton
                colorScheme={'white'}
                color={activityPriority.color}
                variant="outline"
                aria-label="Priority"
                borderRadius={'50px'}
                size="sm"
                p="1px"
                w="20px"
                icon={<Icon as={FaFlag} />}
                onClick={() => {
                  setActivity((prevState) => ({ ...prevState, flag: activityPriority.flag }))
                }}
              />
              {showPriorities &&
                prioritiesList.map((item) => (
                  <IconButton
                    colorScheme={'gray'}
                    color={item.color}
                    aria-label="Priority"
                    borderRadius={'50px'}
                    variant="outline"
                    size="sm"
                    p="1px"
                    w="20px"
                    icon={<Icon as={FaFlag} />}
                    onMouseOver={() => setShowPriorities(true)}
                    onMouseLeave={() => setShowPriorities(false)}
                    onClick={() => {
                      setActivity((prevState) => ({ ...prevState, flag: item.flag }))
                      setActivityPriority({
                        flag: item.flag,
                        color: item.color,
                        value: item.value
                      })
                    }}
                  />
                ))}
            </HStack>
          </Flex>

          <Flex flexDirection={'row'}>
            <Flex>
              {activityId && (
                <SelectButton
                  name="status"
                  value={activity.status}
                  onChange={handleChange}
                  options={[
                    { value: 'todo', name: 'Pendente' },
                    // { value: 'doing', name: 'Fazendo' },
                    // { value: 'blocked', name: 'Impedida' },
                    // { value: 'postponed', name: 'Adiada' },
                    { value: 'canceled', name: 'Cancelada' },
                    { value: 'done', name: 'Realizada' }
                  ]}
                  optionLabel="name"
                  style={{
                    fontSize: '12px',
                    width: '100%',
                    margin: '0 0 10px 0px'
                  }}
                  pt={{
                    button: {
                      style: {
                        fontSize: '12px',
                        fontWeight: '500',
                        padding: '5px 10px',
                        borderRadius: '20px'
                      }
                    },
                    label: { style: { fontSize: '12px', fontWeight: '500' } }
                  }}
                />
              )}
            </Flex>
          </Flex>
          <Flex w="full" gap="20px" justifyContent={'space-between'} alignItems="flex-end">
            <InputTextarea
              name="description"
              value={activity.description}
              onChange={handleChange}
              placeholder="Descrição da atividade"
              rows={2}
              cols="53"
              style={{
                // width: '100%',
                padding: '10px 10px',
                margin: '0 0 0px 0',
                fontSize: '14px'
              }}
              pt={{
                label: { style: { whiteSpace: 'normal' } },
                token: { style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' } },
                wrapper: { style: { maxWidth: '600px' } }
              }}
            />
            <Button
              w="fit-content"
              borderRadius="20px"
              background={'#49BFFF'}
              color="white"
              colorScheme="blue"
              fontSize={'14px'}
              type="submit"
              p="10px 20px"
            >
              Adicionar
            </Button>
          </Flex>
        </Flex>
      </form>
      <Toast position="bottom-center" ref={toast} />
    </Flex>
  )
}
