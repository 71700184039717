// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue
} from '@chakra-ui/react'
import axios from '../../../api/axios'
// Assets
// Custom components
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import SignInButtons from 'views/public/default/components/SignInButtons'
import { TimeLineHome } from 'components/dataDisplay/timeLine'
import SignInForm from '../../auth/signIn/components/SignInForm'

export default function PublicHome() {
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white')
  const boxBg = useColorModeValue('#f2f6ef', 'whiteAlpha.100')

  return (
    <Flex
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      justifyContent={'center'}
      alignItems={'center'}
      height={'80vh'}
    >
      <Flex
        p={'40px'}
        direction="column"
        background={'white'}
        style={{
          borderRadius: '20px',
          alignItems: 'center',
          width: 'fit-content'
        }}
        boxShadow={'2xl'}
      >
        <SignInForm />
      </Flex>
    </Flex>
  )
}
