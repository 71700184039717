import { useParams } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Button, FormControl, Flex, Tag, Text, useColorModeValue } from '@chakra-ui/react'

import { MultiSelect } from 'primereact/multiselect'
import { SelectButton } from 'primereact/selectbutton'
import { FloatLabel } from 'primereact/floatlabel'
import { IconField } from 'primereact/iconfield'
import { InputText } from 'primereact/inputtext'
import { InputIcon } from 'primereact/inputicon'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
// import { Button } from 'primereact/button'
import { Slider } from 'primereact/slider'
import { Toast } from 'primereact/toast'
import Card from 'components/card/Card'

import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { FaUserTie, FaHandshake, FaIndustry, FaFileAlt, FaBuilding } from 'react-icons/fa'

// Tem que passar o DEAL ID e o para o componente pai, para então chamar as atividades referentes ao DEAL.

export default function CompanyForm(props) {
  const textColor = useColorModeValue('navy.700', 'white')
  const account_id = getUserAccountId()

  const { deal_id: dealIdFromParams } = useParams() || ''
  const {
    stepId,
    pipelineId: pipelineIdProps,
    onUpdate,
    dealData,
    inicialFocus,
    companyId,
    onClose
  } = props || null

  const [deal_id, setDealId] = useState(dealIdFromParams)
  const [companyList, setCompanyList] = useState([])
  const [contactList, setContactList] = useState([])
  const [pipelineId, setPipelineId] = useState(pipelineIdProps || null)
  const [pipelineItems, setPipelineItems] = useState([])
  const [pipelineList, setPipelineList] = useState([])
  const [contactDropdownActive, setContactDropdownActive] = useState(false)

  const [formData, setFormData] = useState({
    account_id: account_id,
    company_id: (companyId && parseInt(companyId, 10)) || null,
    contact_id: null,
    name: '',
    deal_data: {
      description: ''
    },
    step_id: parseInt(stepId, 10)
  })

  useEffect(() => {
    console.log('formData DealForm', formData)
    if (account_id && pipelineId) {
      getDealPipelineItems()
    }

    if (formData.company_id) {
      setContactDropdownActive(true)
    } else {
      setContactDropdownActive(false)
    }
  }, [formData, pipelineId])

  useEffect(() => {
    getDealData()
    getAccountContacts()
    getAccountCompanies()
    getAccountPipelines()
  }, [])

  async function getDealData() {
    if (!deal_id) return

    try {
      const { data } = await axios.get(`/deals/${deal_id}`, { headers: authHeader() })
      setFormData({
        account_id: data.account_id,
        company_id: data.company_id,
        contact_id: data.contact_id,
        name: data.name,
        deal_data: {
          description: data.deal_data.description
        },
        step_id: data.step_id
      })
      dealData(data)
      setPipelineId(data.step.pipeline_id)
      // console.log('DEAL DATA: ', data)
    } catch (error) {
      console.log('Erro ao carregar dados da empresa: ', error)
    }
  }

  // Seleção de funil para criação de novos deals fora da SellerDash
  async function getAccountPipelines() {
    if (!account_id) return

    try {
      const response = await axios.get(`/pipelines?account_id=${account_id}&$sort[order]=1`, {
        headers: authHeader(),
        params: {
          $select: ['id', 'name']
        }
      })
      setPipelineList(response.data.data)
      // console.log('pipelineItems: ', response.data.data)
      if (!formData.step_id) {
        return setPipelineId(response.data.data[0].id)
      }
    } catch (error) {
      console.log('Erro ao carregar pipeline: ', error)
    }
  }

  async function getDealPipelineItems() {
    try {
      const response = await axios.get(
        `/steps?account_id=${account_id}&pipeline_id=${pipelineId}&$sort[order]=1`,
        { headers: authHeader() }
      )
      // console.log('Steps: ', response.data.data)
      setPipelineItems(response.data.data)
      if (!formData.step_id) {
        return setFormData((prevState) => ({
          ...prevState,
          step_id: response.data.data[0].id
        }))
      }
      // console.log('pipelineItems: ', response.data.data)
    } catch (error) {
      console.log('Erro ao carregar pipeline: ', error)
    }
  }

  async function getAccountCompanies() {
    if (!account_id) return

    try {
      const response = await axios.get(`/companies?account_id=${account_id}`, {
        headers: authHeader()
      })
      setCompanyList(response.data.data)
    } catch (error) {
      console.log('Erro ao carregar pipeline: ', error)
    }
  }

  async function getAccountContacts() {
    if (!account_id) return

    try {
      const response = await axios.get(`/contacts?account_id=${account_id}`, {
        headers: authHeader()
      })

      const dadosIndexados = response.data.data.reduce((acc, item) => {
        const { id, account_id, company_id, title, first_name, last_name, position, ...rest } = item
        const name = `${first_name} ${last_name}`

        acc[company_id] = acc[company_id] || [] // Cria um array vazio se não existir
        acc[company_id].push({
          id,
          account_id,
          company_id,
          title,
          name,
          position,
          ...rest
        })

        return acc
      }, {})

      // setContactList(response.data.data)
      setContactList(dadosIndexados)
      console.log('Response.Data getDealPipeline', response.data.data)
    } catch (error) {
      console.log('Erro ao carregar pipeline: ', error)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const newState = { ...prevState }

      const keys = name.split('.') // Divide o name em um array
      let temp = newState

      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) {
          temp[keys[i]] = {}
        }
        temp = temp[keys[i]]
      }

      temp[keys[keys.length - 1]] = value

      return newState
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (deal_id) {
      try {
        const response = await axios.patch(`/deals/${deal_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        toastSuccess('Negócio atualizado.')
      } catch (error) {
        toastError('Erro ao atualizar o negócio')
        console.error('Ocorreu um erro ao atualizar o negócio:', error)
      }
    } else {
      try {
        const response = await axios.post('/deals', formData, {
          headers: authHeader()
        })
        toastSuccess('Negócio cadastrado.')
        console.log('Negócio cadastrado com sucesso!', response.data)
        setDealId(response.data.id)
        toastError('Erro ao cadastrar o negócio.')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o negócio:', error.stack)
      }
    }
    console.log(formData)
  }

  async function handleDeleteDeal() {
    if (!deal_id) return

    try {
      const response = axios.delete(`/deals/${deal_id}`, { headers: authHeader() })
      console.log('Negócio excluído!', response)
      toastSuccess('Negócio excluído.')
      setTimeout(() => {
        window.location.href = '/admin/pipeline'
      }, 3000)
      //
    } catch (error) {
      console.log('Erro ao excluir o negócio', error)
      toastError('Não foi possível excluir este negócio.')
    }
  }

  const toast = useRef(null)

  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  const contactNameTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{`${option.name}`}</div>
        {option.position && <Text fontSize={'12px'} fontStyle={'italic'}>{`, ${option.position}`}</Text>}
      </div>
    )
  }

  return (
    <>
      <Card style={{ height: 'fit-content' }} p="20px" width={'full'}>
        <Flex width={'full'}>
          <SelectButton
            name="pipelineId"
            value={pipelineId}
            onChange={(e) => setPipelineId(e.target.value)}
            options={pipelineList}
            optionLabel="name"
            optionValue="id"
            required={true}
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0px'
            }}
            pt={{
              button: {
                style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
              },
              label: { style: { fontSize: '12px', fontWeight: '500' } }
            }}
          />
        </Flex>
        <Flex width={'full'}>
          <SelectButton
            name="step_id"
            value={formData.step_id}
            onChange={handleChange}
            options={pipelineItems}
            optionLabel="name"
            optionValue="id"
            required={true}
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0 0 10px 0px'
            }}
            pt={{
              button: {
                style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
              },
              label: { style: { fontSize: '12px', fontWeight: '500' } }
            }}
          />
        </Flex>
        <FormControl>
          <Flex gap="20px" mb="20px">
            <IconField iconPosition="left">
              <InputIcon className="pi pi-briefcase" style={{ marginRight: '20px' }}></InputIcon>
              <InputText
                id="name"
                ref={inicialFocus}
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="p-inputtext ml-6 w-full"
                v-model="value1"
                placeholder="Negócio"
                style={{ fontSize: '1.5rem', fontWeight: '800' }}
              />
            </IconField>
          </Flex>
          <Flex gap="20px" mt="0px" flexDirection={'row'} maxWidth={'99%'}>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <FaBuilding />
              </span>
              <Dropdown
                value={formData.company_id}
                name="company_id"
                onChange={handleChange}
                options={companyList}
                optionLabel="name"
                optionValue="id"
                placeholder="Selecione uma empresa"
                filter
                // valueTemplate={selectedCountryTemplate}
                // itemTemplate={countryOptionTemplate}
                // className="w-full md:w-16rem text-base"
                pt={{
                  root: { width: '100%' },
                  input: {
                    style: {
                      width: '100%',
                      background: '#fafafa',
                      fontSize: '14px'
                    }
                  },
                  trigger: {
                    style: {
                      background: '#fafafa',
                      fontSize: '14px'
                    }
                  },
                  item: {
                    style: {
                      fontSize: '14px',
                      padding: '8px 16px',
                      width: '100%',
                      background: '#fafafa'
                    }
                  },
                  panel: {
                    style: { marginTop: '5px' }
                  }
                }}
              />
            </div>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <FaUserTie />
              </span>
              <Dropdown
                value={formData.contact_id}
                name="contact_id"
                onChange={handleChange}
                options={contactList[formData.company_id]}
                optionLabel="name"
                optionValue="id"
                itemTemplate={contactNameTemplate}
                placeholder="Selecione um contato"
                filter
                disabled={!contactDropdownActive}
                // valueTemplate={selectedCountryTemplate}
                // itemTemplate={countryOptionTemplate}
                className=""
                pt={{
                  root: { background: '#fafafa', width: '100%' },
                  input: {
                    style: {
                      background: '#fafafa',
                      fontSize: '14px'
                    }
                  },
                  trigger: {
                    style: {
                      background: '#fafafa',
                      fontSize: '14px'
                    }
                  },
                  item: {
                    style: {
                      fontSize: '14px',
                      padding: '8px 16px',
                      width: '100%',
                      background: '#fafafa'
                    }
                  },
                  panel: {
                    style: { marginTop: '5px' }
                  }
                }}
              />
            </div>
          </Flex>
          <Flex direction="column">
            <InputTextarea
              name="deal_data.description"
              value={formData.deal_data.description}
              onChange={handleChange}
              placeholder="Descreva o negócio..."
              className="w-full text-md my-3 p-2"
              pt={{
                label: { style: { whiteSpace: 'normal' } },
                token: { style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' } },
                wrapper: { style: { maxWidth: '600px' } }
              }}
            />
          </Flex>
        </FormControl>

        <Flex flexDirection={'row-reverse'} style={{ justifyContent: 'flexEnd' }}>
          <Button
            type="submit"
            onClick={handleSubmit}
            className="text-white text-center px-3 py-2"
            colorScheme="blue"
            background="#49BFFF"
            borderRadius="50px"
            fontSize="14px"
            fontWeight="600"
          >
            {deal_id ? 'Atualizar' : 'Adicionar Negócio'}
          </Button>
          {deal_id && (
            <Tag
              colorScheme="gray"
              borderRadius={'50px'}
              variant="outline"
              onClick={handleDeleteDeal}
              cursor={'pointer'}
              px="15px"
              mr="10px"
            >
              Excluir
            </Tag>
          )}
          {!deal_id && (
            <Button
              color="#49BFFF"
              colorScheme="blue"
              borderRadius={'50px'}
              variant="outline"
              onClick={onClose}
              mr="10px"
            >
              Fechar
            </Button>
          )}
        </Flex>
        <Toast position="bottom-center" ref={toast} />
      </Card>
    </>
  )
}
