// Chakra imports
import {
  Avatar,
  Box,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Textarea
} from '@chakra-ui/react'

// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar'
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPeople, MdVerifiedUser } from 'react-icons/md'
import { logout, getUserAuthentication } from 'hooks/authService'
import authHeader from 'hooks/authHeader'
import axios from 'axios'
import Telemedicine from './components/Telemedicine'
import MeetingRoom from './components/MeetingRoom'

export default function UserReports() {
  const { user } = getUserAuthentication()
  const textColor = useColorModeValue('navy.700', 'white')

  const [lost_home, setLostHome] = useState(null)
  const [lost_loved_ones, setLostLovedOnes] = useState(null)
  const [lost_job, setLostJob] = useState(null)
  const [psicologicAssistance, setPsicologicAssistance] = useState(null)

  const [whatchedProfile, setWhatchedProfile] = useState({
    first_name: user && user.name,
    is_user_id: user && user.id,
    user_owner: user && user.id
    // contact_data: [
    //   {
    //     whatchedFlag: {
    //       color: 'yellow',
    //       lost_his_home: lost_home,
    //       lost_loved_ones: lost_loved_ones,
    //       lost_job: lost_job,
    //       want_psicologic_assistance: psicologicAssistance
    //       // anything: null
    //     }
    //     // contact_sos_name: null,
    //     // contact_sos_phone: null,
    //     // has_special_heath_need: null,
    //     // in_company_with: null,
    //     // is_pragnant: null,
    //     // urgent_needs: null,
    //     // helpfull_habilities: null,
    //     // need_work_assistance: null
    //   }
    // ]
  })

  const handleChange = (event) => {
    const { name, value } = event.target

    setWhatchedProfile((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const addContactData = async (event) => {
    event.preventDefault()

    // const { lost_his_home, lost_loved_ones, lost_job, need_work_assistance } = whatchedProfile
    try {
      const whatchedFlag = {
        color: 'yellow',
        lost_his_home: lost_home || null,
        lost_loved_ones: lost_loved_ones || null,
        lost_job: lost_job || null,
        want_psicologic_assistance: psicologicAssistance || null
      }

      const response = await axios.post(
        '/contacts',
        {
          first_name: user && user.name,
          is_user_id: user && user.id,
          user_owner: user && user.id,
          contact_data: [JSON.stringify(whatchedFlag)]
        },
        {
          headers: authHeader()
        }
      )

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`)
      }

      const data = await response.json()
      console.log('Informações do contato adicionado.', data)
    } catch (error) {
      console.error('Erro ao enviar o texto para a API:', error)
      throw error
    }
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <Telemedicine />
        <Flex>
          <MeetingRoom />
        </Flex>
      </SimpleGrid>
    </Box>
  )
}
