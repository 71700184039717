/* eslint-disable */
import {
  Button,
  Flex,
  Link,
  Icon,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card'
import Menu from 'components/menu/MainMenu'
import React, { useMemo, useState, useEffect } from 'react'
import { MdHeadsetMic } from 'react-icons/md'
import { getUserAuthenticationRole } from 'hooks/authService'
const userRole = getUserAuthenticationRole()
import axios from 'axios'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

const formatDate = (date) => {
  const [datePart, timePart] = date.split(' ')

  return (
    <span>
      <span style={{ fontSize: 'smaller', fontWeight: '500' }}>{datePart}</span>
      <br />
      <span>{timePart}</span>
    </span>
  )
}

export default function RegistroLigacoes(props) {
  const { columnsData, tableData, vonoToken, vonoKey, resultsPerPage } = props

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: resultsPerPage }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage
  } = tableInstance
  initialState.pageSize = 150

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          <Icon as={MdHeadsetMic} width="20px" height="20px" mr={3} color="inherit" />
          Últimas Ligações
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            // console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td fontSize={{ sm: '14px' }} w="70px" borderColor="transparent" pr="5px">
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    <Link
                      href={`https://vono.me/api/recording/${vonoToken}/${vonoKey}?id_record=${row.original.record_id}&is_download=1`}
                    >
                      <IconButton
                        colorScheme="gray"
                        aria-label="Call Segun"
                        size="sm"
                        type="submit"
                        icon={<Icon as={MdHeadsetMic} width="20px" height="20px" color="inherit" />}
                      />
                    </Link>
                  </Text>
                </Td>
                <Td fontSize={{ sm: '14px' }} w="200px" borderColor="transparent" px="5px">
                  <Link
                    href={`https://vono.me/api/recording/${vonoToken}/${vonoKey}?id_record=${row.original.record_id}&is_download=1`}
                  >
                    <Tooltip label={`Data da ligação ${row.original.calldate}`} aria-label="Ligacao">
                      <Flex alignItems="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700" alignItems="center">
                          <span style={{ textWrap: 'nowrap' }}>{formatDate(row.original.calldate)}</span>
                        </Text>
                      </Flex>
                    </Tooltip>
                  </Link>
                </Td>
                <Td fontSize={{ sm: '14px' }} borderColor="transparent" px="5px">
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {row.original.source}
                    </Text>
                  </Flex>
                </Td>
                <Td fontSize={{ sm: '14px' }} borderColor="transparent" px="5px">
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.duration2}
                  </Text>
                </Td>
                <Td fontSize={{ sm: '14px' }} borderColor="transparent" pl="5px">
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    <Link href={`callto:${row.original.destination}`}>
                      <span
                        style={{
                          padding: '2px 10px',
                          borderRadius: '50px',
                          background: '#eaeaea',
                          fontSize: '12px',
                          color: '#8f9bba'
                        }}
                      >
                        {row.original.destination}
                      </span>
                    </Link>
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage} size="sm">
          Anterior
        </Button>
        <Text fontSize="sm">
          Página{' '}
          <strong>
            {pageIndex + 1} de {Math.ceil(data.length / pageSize)}
          </strong>
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage} size="sm">
          Próxima
        </Button>
      </Flex>
    </Card>
  )
}
