import { Flex } from '@chakra-ui/react'
import UsersTable from './components/usersTable'
import { getUserAccountId } from 'hooks/authService'
import React, { useEffect } from 'react'

export function Users() {
  return (
    <Flex>
      <UsersTable />
    </Flex>
  )
}
