import { Box, Button, Flex, Grid, GridItem, IconButton, Text } from '@chakra-ui/react'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Importe o CSS do Quill
import './style.css' // Importe o CSS do Quill
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { useLocation } from 'react-router-dom'
import { MdEdit } from 'react-icons/md'
import { Toast } from 'primereact/toast'

const TemplateForm = () => {
  const AccountId = getUserAccountId()

  const location = useLocation()
  const templateId = parseInt(location.pathname.replace('/admin/template/', ''), 10)

  const [editorContent, setEditorContent] = useState('')

  const [formData, setFormData] = useState({
    account_id: AccountId,
    name: '',
    channel: 'Email',
    subject: '',
    message: ''
  })
  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.on('text-change', () => {
        setEditorContent(editor.root.innerHTML)
      })
    }
    getTemplate()
  }, [])

  useEffect(() => {
    setFormData({ ...formData, message: { content: editorContent }, account_id: AccountId })
  }, [editorContent, AccountId])

  useEffect(() => {
    console.log('formData Template: ', formData)
  }, [formData])

  function handleChange(event) {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  async function getTemplate() {
    if (!templateId) return

    try {
      const response = await axios.get(`/templates/${templateId}`, { headers: authHeader() })
      setFormData({
        account_id: response.data.account_id,
        name: response.data.name,
        channel: response.data.channel,
        subject: response.data.subject,
        message: response.data.message
      })
      setEditorContent(response.data.message.content)
      console.log('getAccountTemplates', response)
    } catch (error) {
      console.log('Erro ao carregar os templates', error)
    }
  }

  async function submitForm(event) {
    event.preventDefault()

    if (templateId) {
      try {
        const response = await axios.patch(`/templates/${templateId}`, formData, { headers: authHeader() })
        console.log(response)
        toastSuccess('Template atualizado com sucesso!')
      } catch (error) {
        toastError('Erro ao atualizar o template')
        console.log('Erro ao atualizar o template:', error)
      }
    } else {
      try {
        const response = await axios.post('/templates', formData, { headers: authHeader() })
        toastSuccess('Template criado com sucesso!')
        console.log(response)
      } catch (error) {
        toastError('Erro ao criar o template')
        console.log('Erro ao criar o template:', error)
      }
    }
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <Box gap="20px" pt={'90px'}>
      <Grid templateColumns="repeat(4, 1fr)" mb="20px" gap="20px">
        <GridItem w="100%" colSpan={1}>
          <Flex background={'white'} borderRadius={'20px'} p="20px" flexDirection={'column'} gap="20px">
            <Flex flexDirection={'column'}>
              <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
                Nome do modelo*
              </label>
              <InputText
                name="name"
                value={formData.name}
                onChange={handleChange}
                focusBorderColor="transparent"
                placeholder="..."
                style={{
                  padding: '8px',
                  width: '100%',
                  background: '#fafafa'
                }}
                className="outline-none"
                required
              />
            </Flex>
            <Flex flexDirection={'column'}>
              <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>
                Tipo de mensagem*
              </label>
              <Dropdown
                value={formData.channel}
                onChange={handleChange}
                options={[
                  { value: 'WhatsApp', name: 'WhatsApp' },
                  { value: 'Email', name: 'Email' },
                  { value: 'LinkedIn', name: 'LinkedIn' },
                  { value: 'Direct', name: 'Direct' }
                ]}
                name="channel"
                optionLabel="name"
                display="chip"
                maxSelectedLabels={0}
                // className={'mt-4'}
                style={{
                  fontSize: '12px',
                  width: '100%',
                  margin: '0px 0 10px 0'
                }}
                pt={{
                  label: { style: { whiteSpace: 'normal' } },
                  token: {
                    style: { margin: '0 6px 6px 0', padding: '2px 5px 2px 8px' }
                  },
                  wrapper: { style: { maxWidth: '600px' } }
                }}
                required
              />
            </Flex>
            <Button
              colorScheme="blue"
              borderRadius={'50px'}
              background={'#48Bfff'}
              width={'fit-content'}
              mt="20px"
              onClick={(e) => submitForm(e)}
            >
              Salvar
            </Button>
          </Flex>
        </GridItem>
        <GridItem w="100%" colSpan={3}>
          <Flex
            background={'white'}
            borderRadius={'20px'}
            p="10px"
            height={'fit-content'}
            flexDirection={'column'}
          >
            <Flex flexDirection={'column'} mx="10px" py="10px">
              <label style={{ fontSize: '14px', paddingLeft: '10px', fontWeight: '600' }}>Assunto</label>
              <InputText
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                focusBorderColor="transparent"
                placeholder="..."
                style={{
                  padding: '8px',
                  width: '100%',
                  background: '#fafafa'
                }}
                className="outline-none"
                required
              />
            </Flex>
            <ReactQuill
              ref={quillRef}
              value={editorContent}
              onChange={setEditorContent}
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ align: [] }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  ['link', 'image', 'video', 'formula'],
                  ['clean'] // Adicione mais opções da barra de ferramentas conforme necessário
                ]
              }}
            />
          </Flex>
        </GridItem>
      </Grid>
      <Toast position="bottom-center" ref={toast} />
    </Box>
  )
}

export default TemplateForm
