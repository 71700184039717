// Chakra imports
import { Box, Button, Flex, Icon, IconButton, Link, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import Footer from 'components/footer/FooterAuth'
import FixedPlugin from 'components/fixedPlugin/FixedPlugin'
// Custom components
import { NavLink } from 'react-router-dom'
// Assets
import { FaChevronLeft } from 'react-icons/fa'

function AuthIllustration(props) {
  const { children, illustrationBackground, backgroundColor, quote } = props
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content" backgroundColor={backgroundColor}>
      <LinkBox background={'white'} w="fit-content" h="fit-content" p={'10px 15px'} borderRadius={'20px'}>
        <LinkOverlay href="/public/home">
          <Flex
            background={'white'}
            align="center"
            ps={{ base: '25px', lg: '0px' }}
            pt={{ lg: '0px', xl: '0px' }}
            w="fit-content"
            h="fit-content"
          >
            <Icon as={FaChevronLeft} me="12px" h="13px" w="8px" color="secondaryGray.1000" />
            <Text ms="0px" fontSize="sm" color="secondaryGray.1000">
              Voltar
            </Text>
          </Flex>
        </LinkOverlay>
      </LinkBox>
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh'
          // xl: '97vh'
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
        >
          <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="start"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
          >
            {quote && (
              <Text
                fontSize="28px"
                fontWeight={1000}
                maxW="70%"
                mt="20%"
                px={10}
                color="#ffffff"
                lineHeight="36px"
              >
                {quote}
              </Text>
            )}
          </Flex>
        </Box>
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  )
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any
}

export default AuthIllustration
