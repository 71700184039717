import React, { useState, useEffect } from 'react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId, getUserAuthentication } from 'hooks/authService'
import Card from 'components/card/Card'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'
import { Avatar, Box, Flex, Link, Icon, Text, useColorModeValue, SimpleGrid, Wrap } from '@chakra-ui/react'
import Clients from './components/clientsTable'
import AnimalsAgeDonutCard from '../default/components/DonutCardAnimalsAge'
import StatusDonutChartCard from '../default/components/DonutCardStatus'
import DonutChartCardSpecies from '../default/components/DonutCardSpecies'

export default function Settings() {
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')

  const [dados, setData] = useState([])
  const [emptyData, setEmptyData] = useState(null)

  const account_id = getUserAccountId()

  console.log('account_id:', account_id)

  useEffect(() => {
    async function fetchData() {
      try {
        const request = await axios.get(`/companies?account_id=${account_id}`, {
          query: {
            $limit: 15,
            $sort: {
              name: 1
            }
          },
          headers: authHeader()
        })
        setData(request.data.data)
        console.log('request.data', request.data)
      } catch (error) {
        setEmptyData('Adicione uma empresa, ou importe uma lista de empresas.')
      }
    }

    fetchData()
  }, [])

  return (
    <Box pt={{ base: '30px', md: '80px', xl: '80px' }}>
      {emptyData ? (
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
          background="transparent"
        >
          <Flex px="25px" justify="center" mb="20px" align="center" flexDirection="column" gap={10}>
            <Text color={textColor} fontSize="18px" fontWeight="500" lineHeight="100%" pt="50px">
              {emptyData}
            </Text>
            <Flex gap={10} alignItems="center">
              <Link href="/admin/new-company">
                <SimpleGrid
                  rows={2}
                  justifyItems="center"
                  gap={2}
                  boxShadow="xl"
                  p="6"
                  rounded="xl"
                  bg="white"
                  w="120px"
                >
                  <Icon as={MdPersonAdd} color={iconColor} w="24px" h="24px" />
                  <Text px="5px" fontSize="lg">
                    Novo
                  </Text>
                </SimpleGrid>
              </Link>
              <Link href="/admin/import-companies">
                <SimpleGrid
                  rows={2}
                  justifyItems="center"
                  gap={2}
                  boxShadow="xl"
                  p="6"
                  rounded="xl"
                  bg="white"
                  w="120px"
                >
                  <Icon as={MdImportExport} color={iconColor} w="24px" h="24px" />
                  <Text px="5px" fontSize="lg">
                    Importar
                  </Text>
                </SimpleGrid>
              </Link>
            </Flex>
            {/* <Menu /> */}
          </Flex>
        </Card>
      ) : (
        <SimpleGrid rows={2}>
          {/* <Wrap minChildWidth="300px" maxChildWidth="150px" columns={6} mb="20px" alignItems={'center'}>
            <StatusDonutChartCard width={'170px'} />
          </Wrap> */}

          <Clients
            columnsData={[
              {
                Header: 'Empresa',
                accessor: 'name'
              },
              {
                Header: 'Telefone',
                accessor: 'whatsapp'
              },
              {
                Header: 'Email',
                accessor: 'email'
              }
              // {
              //   Header: 'Contatos',
              //   accessor: 'contatos  '
              // },
              // {
              //   Header: 'Último contato',
              //   accessor: 'last_visit  '
              // }
            ]}
            tableData={dados}
          />
        </SimpleGrid>
      )}
    </Box>
  )
}
