/* eslint-disable */
import { Button, Flex, Link, Tag, Text, useColorModeValue } from '@chakra-ui/react'
import { FaEnvelope, FaInstagram, FaLinkedin, FaPhone, FaSearch, FaTwitter } from 'react-icons/fa'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'
import { getUserAuthenticationRole } from 'hooks/authService'
import pipelineStatus from 'components/setPipelineStatusName'
const userRole = getUserAuthenticationRole()

// import Menu from 'components/menu/MainMenu'
import React, { useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { SearchBar } from 'components/navbar/searchBar/SearchBar'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { InputText } from 'primereact/inputtext'

export default function ProductsAndServicesTable() {
  const [status, setStatus] = useState('')

  const handleChangeStatus = (event) => {
    setStatus(event.value)
  }

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')

  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'address.municipio': { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'address.uf': { value: null, matchMode: FilterMatchMode.CONTAINS }
  })

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <Flex justifyContent={'space-between'} gap={'10px'}>
        <Text fontSize={'24px'} fontWeight={'800'} color={'#1b2559'}>
          Produtos e Serviços
        </Text>
        <Flex alignItems={'center'} background={'#f4f7fe'} borderRadius={'20px'}>
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                background: 'transparent'
              }}
            >
              <FaSearch />
            </span>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Buscar"
              v-model="value1"
              style={{
                padding: ' 0px 10px',
                width: '100%',
                background: 'transparent',
                fontSize: '14px'
              }}
              className="outline-none"
            />
          </div>
        </Flex>
        <Flex gap={'10px'} alignItems={'center'}>
          <Link href="/admin/new-company">
            <Button
              leftIcon={<MdPersonAdd fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Novo
            </Button>
          </Link>
          <Link href="/admin/import-products">
            <Button
              leftIcon={<MdImportExport fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Importar
            </Button>
          </Link>
        </Flex>
      </Flex>
    )
  }

  const header = renderHeader()

  const item = (item) => {
    return (
      <Link href={`/admin/company/${item.id}`}>
        <Flex flexDirection={'column'} color="#1b2559">
          <Flex flexDirection="column">
            <Text fontSize={'14px'} fontWeight={'600'}>
              {item.name}
            </Text>
            <Text fontSize={'12px'} fontWeight={'400'}>
              <span>{`# `}</span>
              {item.cod}
            </Text>
          </Flex>
        </Flex>
      </Link>
    )
  }

  const category = (item) => {
    return (
      <Flex gap={'5px'} wrap={'wrap'}>
        {item.category}
      </Flex>
    )
  }

  const price = (item) => {
    return (
      <Flex color="#1b2559">
        <Text fontSize={'14px'} fontWeight={'400'}>
          {`R$ ${item.price.toLocaleString('pt-BR')}`}
        </Text>
      </Flex>
    )
  }

  const itemStatus = (item) => {
    const { status, color } = pipelineStatus(item.status)
    return <Flex color="#1b2559">{item.status}</Flex>
  }

  const tableData = [
    {
      id: 1,
      cod: '7892987987',
      name: 'Assinatura mensal',
      category: 'Serviço',
      description: 'Descrição do produto ou serviço',
      cost: 554.0,
      price: 1500.0,
      unity: 'Mensal',
      status: 'Ativo'
    },
    {
      id: 2,
      cod: '7892987943',
      name: 'Tv Smart 50"',
      category: 'Produto',
      description: 'Descrição da TV',
      cost: 554.0,
      price: 2500.0,
      unity: 'Unidade',
      status: 'Ativo'
    },
    {
      id: 1,
      cod: '789298733',
      name: 'Criação de Identidade Visual',
      category: 'Serviço',
      description: 'Descrição do produto ou serviço',
      cost: 554.0,
      price: 2500.0,
      unity: 'Unidade',
      status: 'Ativo'
    }
  ]

  return (
    <Flex gap={'20px'} flexDirection={'column'}>
      <Flex bg={'white'} p={'20px'} borderRadius={'20px'} width={'fit-content'}>
        <DataTable
          value={tableData}
          header={header}
          filters={filters}
          paginator
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="id"
          globalFilterFields={['name', 'contact', 'address', 'address.municipio', 'address.uf']}
          tableStyle={{ width: '100vh' }}
          pt={{
            header: {
              style: {
                background: '#fff'
              }
            }
          }}
        >
          <Column
            field="name"
            header="Item"
            body={item}
            style={{ minWidth: '350px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>

          <Column
            field="address"
            header="Preço"
            body={price}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="contact"
            header="Categoria"
            body={category}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
          <Column
            field="pipeline"
            header="Status"
            body={itemStatus}
            style={{ minWidth: '150px' }}
            pt={{
              headerCell: {
                style: {
                  background: '#fff',
                  borderBottom: '1px solid #e2e8f0'
                }
              }
            }}
          ></Column>
        </DataTable>
      </Flex>
    </Flex>
  )
}
