import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
import { MdEditCalendar, MdCheck, MdClose } from 'react-icons/md'
import { app } from 'api/feathers'
import { getUserAuthenticationRole } from 'hooks/authService'
import AccountForm from 'views/admin/formAccount/components/accountForm'

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import authHeader from 'hooks/authHeader'
const userRole = getUserAuthenticationRole()

const columnsData = [
  { Header: 'Empresa', accessor: '' },
  { Header: 'Colaboradores', accessor: '' },
  { Header: 'Contato', accessor: '' },
  { Header: 'Contas', accessor: '' },
  { Header: 'Clientes', accessor: '' },
  { Header: 'Status', accessor: '' }
]

export default function CampaignsTable() {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    async function buscarCampanhas() {
      try {
        const { data } = await app.service('accounts').find({
          headers: authHeader(),
          paginate: 20
        })
        setTableData(data)
        console.log(data)
      } catch (error) {
        console.error('Erro ao buscar campanhas:', error)
      }
    }

    buscarCampanhas()
  }, [])

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 150

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Contas
        </Text>
        <Flex gap={5} alignItems="center">
          <Link href="/admin/new-account">
            <Flex flexDirection="rows" alignItems="center" gap={2}>
              <Icon as={MdEditCalendar} color={iconColor} w="26px" h="26px" />

              <Text px="5px" fontSize="sm">
                Criar
              </Text>
            </Flex>
          </Link>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            // console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/admin/campaign/${row.original.id}`}>
                    <Text color={textColor} fontSize="sm" fontWeight="600">
                      {row.original.name}
                    </Text>
                    <Text>{row.original?.business_data?.cnpj}</Text>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex alignItems="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {row.original.business_data?.employees}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="left" flexDirection="column">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="600">
                      {row.original.contact_data?.emails[0]?.address}
                    </Text>
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {row.original.contact_data?.phones[0]?.number}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      6.272
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      7.142
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.status === 'active' && (
                      <Icon as={MdCheck} color="green" w="20px" h="20px" />
                    )}
                    {row.original.status === 'inactive' && (
                      <Icon as={MdClose} color="red" w="20px" h="20px" />
                    )}
                    {row.original.status === 'new' && <Icon as={MdCheck} color="blue" w="20px" h="20px" />}
                    {row.original.status}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
