import React, { useState, useEffect, useRef } from 'react'
import axios from 'api/axios'
import {
  Button,
  Flex,
  IconButton,
  Input,
  Icon,
  Select,
  SimpleGrid,
  Tag,
  Text,
  Textarea
} from '@chakra-ui/react'
import { FaPhone, FaTasks, FaEnvelope, FaComments, FaHandshake, FaWalking, FaFlag } from 'react-icons/fa'
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { SelectButton } from 'primereact/selectbutton'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { OverlayPanel } from 'primereact/overlaypanel'

export default function Activities({ activity, refreshComponent, templatesList }) {
  const [newData, setNewData] = useState({
    // account_id: null,
    // owner_id: null,
    // entity: '',
    // entity_id: null,
    // name: '',
    description: activity.description,
    // type: '',
    status: activity.status,
    // coments: activity.coments,
    dead_line: activity.dead_line,
    flag: activity.flag
  })

  const template = templatesList.find((item) => item.id === activity.activity_data.template_id)
  console.log(`template Da ATIVIDADE: ${activity.id} - `, template)

  const op = useRef(null)

  function RenderActivityIcon({ value }) {
    const activity = activityMapping[value]

    if (!activity) {
      return <span>Ops..</span>
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
        <span style={{ marginRight: '8px' }}>{activity.icon}</span>
        <span>{activity.name}</span>
      </div>
    )
  }

  function convertTimestampToDateAndTime(timestamp) {
    // Remove os três últimos dígitos do timestamp
    const date = new Date(Math.floor(timestamp / 1000) * 1000)
    const day = date.getDate().toString().padStart(2, '0')
    const month =
      date.toLocaleString('default', { month: 'short' }).charAt(0).toUpperCase() +
      date.toLocaleString('default', { month: 'short' }).slice(1)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${day}/${month} às ${hours}h${minutes}`
  }

  const activityMapping = {
    call: { icon: <FaPhone />, name: 'Ligação' },
    task: { icon: <FaTasks />, name: 'Tarefa' },
    email: { icon: <FaEnvelope />, name: 'Email' },
    message: { icon: <FaComments />, name: 'Mensagem' },
    meeting: { icon: <FaHandshake />, name: 'Reunião' },
    visit: { icon: <FaWalking />, name: 'Visita' }
  }

  const [status, setStatus] = useState(activity.status)

  async function updateActivity(data) {
    try {
      const response = await axios.patch(`/activities/${activity.id}`, data, { headers: authHeader() })
      console.log('Atividade atualizada!', response)
      refreshComponent()
    } catch (error) {
      console.log('Não foi possível atualizar a atividade.', error)
    }
  }

  function handleChange(event) {
    const { name, value } = event.target
    setNewData((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setStatus(value)
    updateActivity({ [name]: value })
  }

  useEffect(() => {
    console.log('activity change newData: ', newData)
  }, [newData])

  console.log('activity.dead_line', activity.dead_line)

  const prioritiesList = [
    {
      value: 'Baixa prioridade',
      flag: 'low',
      color: '#49BFFF'
    },
    {
      value: 'Prioridade normal',
      flag: 'normal',
      color: '#e9e9e9'
    },
    {
      value: 'Alta prioridade',
      flag: 'high',
      color: '#F6E05E'
    },
    {
      value: 'Máxima prioridade ',
      flag: 'top',
      color: '#E53E3E'
    }
  ]

  function getColorByFlag(flag) {
    const priority = prioritiesList.find((item) => item.flag === flag)
    return priority ? priority.color : 'transparent'
  }

  return (
    <Flex p="10px" borderRadius="20px" w="100%" flexDirection="column">
      <Flex
        flexDirection="row"
        mb="10px"
        pb="3px"
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: '1px solid #ededed' }}
      >
        <Flex>
          <RenderActivityIcon value={activity.type} />
          <Tag colorScheme="green" borderRadius={'20px'} mr="20px" fontSize="0.9rem" width={'fit-content'}>
            {activity.name === 'automation' && activity.name}
          </Tag>
          <Flex>
            <Text mr="20px" fontSize="0.9rem" width={'fit-content'}>
              {activity.start_date && convertTimestampToDateAndTime(activity.start_date)}
            </Text>
            <Text mr="20px" fontSize="0.9rem" width={'fit-content'}>
              {activity.dead_line && convertTimestampToDateAndTime(activity.dead_line)}
            </Text>
          </Flex>
        </Flex>
        <Flex gap="10px">
          <IconButton
            colorScheme={'white'}
            color={getColorByFlag(activity.flag)}
            // variant="outline"
            aria-label="Priority"
            borderRadius={'50px'}
            size="sm"
            p="1px"
            w="20px"
            icon={<Icon as={FaFlag} />}
          />
          <SelectButton
            name="status"
            value={newData.status}
            onChange={handleChange}
            options={[
              { value: 'todo', name: 'Pendente' },
              { value: 'doing', name: 'Agendado' }, // alterar para scheduled
              // { value: 'blocked', name: 'Impedida' },
              // { value: 'postponed', name: 'Adiada' },
              { value: 'canceled', name: 'Cancelada' },
              { value: 'done', name: 'Realizada' }
            ]}
            optionLabel="name"
            optionValue="value"
            style={{
              fontSize: '12px',
              width: '100%',
              margin: '0px'
            }}
            pt={{
              button: {
                style: { fontSize: '12px', fontWeight: '500', padding: '5px 10px', borderRadius: '20px' }
              },
              label: { style: { fontSize: '12px', fontWeight: '500' } }
            }}
          />
        </Flex>
      </Flex>
      <Text fontSize={'14px'}>{activity.description}</Text>
      {activity.activity_data?.template_id && (
        <>
          <OverlayPanel ref={op} style={{ maxWidth: '600px', padding: '15px', borderRadius: '20px' }}>
            <Flex flexDirection={'column'}>
              <Text
                fontSize={'14px'}
                pb="10px"
                my="5px"
                borderBottom={'1px solid #ccc'}
              >{`Assunto: ${template.subject}`}</Text>
              <Text pt="10px" dangerouslySetInnerHTML={{ __html: template.message.content }} />
            </Flex>
          </OverlayPanel>
          <Tag
            background={'#fafafa'}
            p={'3px 15px'}
            fontSize={'14px'}
            onClick={(e) => op.current.toggle(e)}
            cursor={'pointer'}
            width={'fit-content'}
            borderRadius={'20px'}
          >
            {`${template.name}`}
          </Tag>
        </>
      )}
      {/* <Textarea placeholder="Comentário" /> */}

      {/* <Button bg="#49BFFF" color="white" colorScheme="blue" borderRadius="20px" fontSize={'14px'}>
                Salvar
              </Button> */}
    </Flex>
  )
}
