// import React from 'react'
// import ReactDOM from 'react-dom'
// import 'assets/css/App.css'
// import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
// import AuthLayout from 'layouts/auth'
// import PublicLayout from 'layouts/public'
// import AdminLayout from 'layouts/admin'
// import { ChakraProvider } from '@chakra-ui/react'
// import { PrimeReactProvider, locale, addLocale } from 'primereact/api'
// import theme from 'theme/theme'
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui'

// // Adicionamos o PrimeReact
// import 'primereact/resources/themes/lara-light-blue/theme.css'
// import 'primereact/resources/primereact.min.css'
// import 'primeicons/primeicons.css'
// import 'primeflex/primeflex.css'
// import './custom.css'

// ReactDOM.render(
//   <ChakraProvider theme={theme}>
//     <PrimeReactProvider value={{}}>
//       <React.StrictMode>
//         <ThemeEditorProvider>
//           <BrowserRouter>
//             <Switch>
//               <Route path={`/public`} component={PublicLayout} />
//               <Route path={`/admin`} component={AdminLayout} />
//               <Route path={`/auth`} component={AuthLayout} />
//               <Redirect from="/" to="/public" />
//             </Switch>
//           </BrowserRouter>
//         </ThemeEditorProvider>
//       </React.StrictMode>
//     </PrimeReactProvider>
//   </ChakraProvider>,
//   document.getElementById('root')
// )

// Inclusão de tradução dos componentes do PrimeReact
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import 'assets/css/App.css'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import AuthLayout from 'layouts/auth'
import PublicLayout from 'layouts/public'
import AdminLayout from 'layouts/admin'
import { ChakraProvider } from '@chakra-ui/react'
import { PrimeReactProvider, addLocale, locale } from 'primereact/api'
import theme from 'theme/theme'
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui'

import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
// import './custom.css'

const App = () => {
  useEffect(() => {
    // Adiciona e define 'pt-br' como o locale padrão para o PrimeReact
    addLocale('pt-br', {
      accept: 'Sim',
      addRule: 'Adicionar Regra',
      am: 'AM',
      apply: 'Aplicar',
      cancel: 'Cancelar',
      choose: 'Selecione',
      chooseDate: 'Selecione a Data',
      chooseMonth: 'Selecione o Mês',
      chooseYear: 'Selecione o Ano',
      clear: 'Limpar',
      completed: 'Concluído',
      contains: 'Contém',
      custom: 'Personalizado',
      dateAfter: 'Data depois de',
      dateBefore: 'Data antes de',
      dateFormat: 'dd/mm/aa',
      dateIs: 'Data é',
      dateIsNot: 'Data não é',
      dayNames: [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado'
      ],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      emptyFilterMessage: 'Nenhum resultado encontrado',
      emptyMessage: 'Nenhuma opção disponível',
      emptySearchMessage: 'Nenhum resultado encontrado',
      emptySelectionMessage: 'Nenhum item selecionado',
      endsWith: 'Termina com',
      equals: 'Igual',
      fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      filter: 'Filtro',
      firstDayOfWeek: 0,
      gt: 'Maior que',
      gte: 'Maior ou igual a',
      lt: 'Menor que',
      lte: 'Menor ou igual a',
      matchAll: 'Corresponder Todos',
      matchAny: 'Corresponder Qualquer',
      medium: 'Médio',
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro ',
        'Dezembro'
      ],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      nextDecade: 'Próxima Década',
      nextHour: 'Próxima Hora',
      nextMinute: 'Próximo Minuto',
      nextMonth: 'Próximo Mês',
      nextSecond: 'Próximo Segundo',
      nextYear: 'Próximo Ano',
      noFilter: 'Sem Filtro',
      notContains: 'Não contém',
      notEquals: 'Diferente',
      now: 'Agora',
      passwordPrompt: 'Digite uma senha',
      pending: 'Pendente',
      pm: 'PM',
      prevDecade: 'Década Anterior',
      prevHour: 'Hora Anterior',
      prevMinute: 'Minuto Anterior',
      prevMonth: 'Mês Anterior',
      prevSecond: 'Segundo Anterior',
      prevYear: 'Ano Anterior',
      reject: 'Não',
      removeRule: 'Remover Regra',
      searchMessage: 'Existem {0} resultados disponíveis',
      selectionMessage: '{0} itens selecionados',
      showMonthAfterYear: false,
      startsWith: 'Começa com',
      strong: 'Forte',
      today: 'Hoje',
      upload: 'Enviar',
      weak: 'Fraco',
      weekHeader: 'Sem',
      aria: {
        cancelEdit: 'Cancelar Edição',
        close: 'Fechar',
        collapseLabel: 'Colapso',
        collapseRow: 'Recolher Linha',
        editRow: 'Editar Linha',
        expandLabel: 'Expandir',
        expandRow: 'Expandir Linha',
        falseLabel: 'Falso',
        filterConstraint: 'Restrição de Filtro',
        filterOperator: 'Operador de Filtro',
        firstPageLabel: 'Primeira Página',
        gridView: 'Visualização de Grade',
        hideFilterMenu: 'Esconder Menu de Filtro',
        jumpToPageDropdownLabel: 'Ir para a Página',
        jumpToPageInputLabel: 'Ir para a Página',
        lastPageLabel: 'Última Página',
        listView: 'Visualização de Lista',
        moveAllToSource: 'Mover Todos para a Origem',
        moveAllToTarget: 'Mover Todos para o Destino',
        moveBottom: 'Mover para o Final',
        moveDown: 'Mover para Baixo',
        moveTop: 'Mover para o Topo',
        moveToSource: 'Mover para a Origem',
        moveToTarget: 'Mover para o Destino',
        moveUp: 'Mover para Cima',
        navigation: 'Navegação',
        next: 'Próximo',
        nextPageLabel: 'Próxima Página',
        nullLabel: 'Não Selecionado',
        otpLabel: 'Insira o caractere da senha de uso único {0}',
        pageLabel: 'Página {page}',
        passwordHide: 'Esconder a senha',
        passwordShow: 'Mostrar senha',
        previous: 'Anterior',
        previousPageLabel: 'Página Anterior',
        removeLabel: 'Remover',
        rotateLeft: 'Rotacionar para a Esquerda',
        rotateRight: 'Rotacionar para a Direita',
        rowsPerPageLabel: 'Linhas por página',
        saveEdit: 'Salvar Edição',
        scrollTop: 'Rolar para o Topo',
        selectAll: 'Todos os itens selecionados',
        selectLabel: 'Selecione',
        selectRow: 'Linha Selecionada',
        showFilterMenu: 'Mostrar Menu de Filtro',
        slide: 'Deslizar',
        slideNumber: 'Slide {slideNumber}',
        star: '1 estrela',
        stars: '{star} estrelas',
        trueLabel: 'Verdadeiro',
        unselectAll: 'Todos os itens desmarcados',
        unselectLabel: 'Desmarcar',
        unselectRow: 'Linha Desmarcada',
        zoomImage: 'Ampliar Imagem',
        zoomIn: 'Ampliar',
        zoomOut: 'Reduzir'
      }
    })
    locale('pt-br')
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <PrimeReactProvider>
        <React.StrictMode>
          <ThemeEditorProvider>
            <BrowserRouter>
              <Switch>
                <Route path={`/public`} component={PublicLayout} />
                <Route path={`/admin`} component={AdminLayout} />
                <Route path={`/auth`} component={AuthLayout} />
                <Redirect from="/" to="/public" />
              </Switch>
            </BrowserRouter>
          </ThemeEditorProvider>
        </React.StrictMode>
      </PrimeReactProvider>
    </ChakraProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
