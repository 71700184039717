import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  Switch,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Textarea,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderTrack,
  SliderThumb,
  Select,
  Stack,
  useColorModeValue,
  Text,
  GridItem
} from '@chakra-ui/react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import formatDate from 'hooks/formatDate'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import ApproachForm from '../../newAproach/components/newApproachForm'
import NewCampaignScript from './campaignScript'
import { getUserAccountId } from 'hooks/authService'
import IntervalInput from 'components/intervalInput'
import { CampaignActivitiesForm } from './campaignActivitiesForm'
import { Toast } from 'primereact/toast'

export default function CampaignForm() {
  const textColor = useColorModeValue('navy.700', 'white')
  const account_id = getUserAccountId()

  const location = useLocation()
  const campaignIdFromParams = parseInt(location.pathname.replace('/admin/campaign/', ''), 10)

  const [accountName, setAccountName] = useState()
  const [campaign_id, setCampaignId] = useState(parseInt(campaignIdFromParams, 10))
  const [pipelineList, setPipelineList] = useState(null)
  const [pipelineSteps, setPipelineSteps] = useState(null)
  const [interval, setInterval] = useState(15780096000.000004)

  const [formData, setFormData] = useState({
    account_id: account_id,
    subject: '',
    description: '',
    pipeline_id: null,
    priority: 5,
    intervals_msec: 15780096000,
    pipeline_activities: {},
    status: 'active',
    start_date: '',
    end_date: ''
  })

  useEffect(() => {
    const newInterval = parseInt(interval, 10)
    if (formData.intervals_msec !== newInterval) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        intervals_msec: newInterval
      }))
    }
  }, [interval, formData.intervals_msec])

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      start_date: getToday().today,
      end_date: getToday().years
    }))
  }, [])

  useEffect(() => {
    console.log('campaign formData: ', formData)
    // console.log('pipelineSteps atualizado: ', pipelineSteps)
    // console.log('pipelineList atualizado: ', pipelineList)
  }, [account_id, formData, pipelineSteps, pipelineList, campaignIdFromParams])

  useEffect(() => {
    getAccountName()
    getCampaign()
    getPipelines()
  }, [])

  useEffect(() => {
    if (!pipelineSteps && formData.pipeline_id) {
      getCampaignPipelineSteps()
    }
  }, [formData])

  async function getAccountName() {
    if (!account_id) return
    try {
      const { data } = await axios.get(`/accounts/${account_id}`, {
        headers: authHeader()
      })
      setAccountName(data.name)
    } catch (err) {
      console.log('Erro: ', err.response?.data || err.message)
    }
  }

  async function getCampaign() {
    if (!campaign_id) return
    try {
      const response = await axios.get(`/campaigns/${campaign_id}`, {
        headers: authHeader()
      })

      // aparece um response.data.pipeline.name
      setFormData({
        account_id: response.data.account_id,
        subject: response.data.subject,
        description: response.data.description,
        pipeline_id: response.data.pipeline_id,
        priority: response.data.priority,
        intervals_msec: response.data.intervals_msec,
        pipeline_activities: response.data.pipeline_activities,
        status: response.data.status,
        start_date: response.data.start_date,
        end_date: response.data.end_date
      })
      console.log('Campanha: ', response.data)
    } catch (error) {
      console.log('Erro: ', error.response?.data || error.message)
    }
  }

  async function getPipelines() {
    // if (!campaign_id) return
    try {
      const response = await axios.get(`/pipelines?account_id=${account_id}`, {
        headers: authHeader()
      })
      // console.log('pipelineList: ', response.data.data)
      setPipelineList(response.data.data)
    } catch (error) {
      console.log('Erro: ', error.response.data)
    }
  }

  async function getCampaignPipelineSteps() {
    try {
      const response = await axios.get(
        `/steps?account_id=${formData.account_id}&pipeline_id=${formData.pipeline_id}`,
        {
          headers: authHeader()
        }
      )
      const sortedData = response.data.data.sort((a, b) => a.order - b.order)
      // console.log('pipelineList em CampaignActivitiesForm: ', response.data.data)
      setPipelineSteps(sortedData)
    } catch (error) {
      console.log('Erro: ', error.response.data)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSelect = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pipeline_id: parseInt(event.target.value, 10)
    }))

    pipelineList.map((item) => {
      console.log('item pipeline:', item.steps)
      if (item.id === parseInt(event.target.value, 10)) {
        setPipelineSteps(item.steps)
      }
    })
  }

  const handleSlideChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      priority: value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (campaign_id) {
      try {
        const response = await axios.patch(`/campaigns/${campaign_id}`, formData, {
          headers: authHeader()
        })
        toastSuccess('Campanha atualizada!')
        console.log('Dados atualizados com sucesso!', response.data)
      } catch (error) {
        toastError('Erro ao atualizar a campanha')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/campaigns', formData, {
          headers: authHeader()
        })
        console.log('Campanha cadastrada com sucesso!', response.data)
        setCampaignId(response.data.id)
        toastSuccess('Campanha criada!')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
        toastError('Erro ao criar a campanha')
      }
    }
    console.log('form Cadastrado:', formData)
  }

  function getToday() {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1 // Months are 0-indexed
    let day = today.getDate()

    if (month < 10) month = `0${month}`
    if (day < 10) day = `0${day}`

    return { today: `${year}-${month}-${day}`, years: `${year + 2}-${month}-${day}` } // Format for input type="date"
  }

  const toast = useRef(null)
  const toastSuccess = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message })
  }
  const toastError = (message) => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(4, 1fr)" gap={'20px'}>
        <GridItem colSpan={1}>
          <Card h="fit-content" boxShadow="lg">
            <FormControl>
              <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
                Processo Comercial
              </Text>
              <Flex direction="column">
                <Input
                  value={formData.subject}
                  name="subject"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  mb="24px"
                  borderRadius="50px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                {/* <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Descrição
              </FormLabel> */}
                <Textarea
                  value={formData.description}
                  name="description"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Descrição da campanha"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                  border="1px solid #E2E8F0"
                  borderRadius="16px"
                />
              </Flex>
              <Flex flexDirection="column" mb="24px">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm">
                  Funil
                </FormLabel>
                <Select
                  name="pipeline_id"
                  value={formData.pipeline_id}
                  onChange={handleSelect}
                  borderRadius="50px"
                  placeholder="Selecione"
                >
                  {pipelineList &&
                    pipelineList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </Flex>

              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Data de Início
                </FormLabel>
                <Input
                  value={formatDate(formData.start_date)}
                  name="start_date"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  type="date"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  mb="24px"
                  borderRadius="50px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Data de Finalização
                </FormLabel>
                <Input
                  value={formatDate(formData.end_date)}
                  name="end_date"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  type="date"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  borderRadius="50px"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex flexDirection="column" mb="24px">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm">
                  Intervalo entre repetições
                </FormLabel>
                <IntervalInput onChange={setInterval} value={formData.intervals_msec} />
              </Flex>

              <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" mb="24px">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm" mb="8px">
                  Status da Campanha
                </FormLabel>
                <Flex alignItems="center">
                  <Switch
                    id="status"
                    checked={formData.status === 'active'} // Determina o estado inicial do switch
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        status: event.target.checked ? 'active' : 'inactive' // Inverte o status ao alternar
                      }))
                    }
                    defaultChecked
                    ml="10px"
                  />
                  <Text ml="10px">{formData.status === 'active' ? 'Ativo' : 'Inativo'}</Text>
                </Flex>
              </Flex>

              <Flex flexDirection="column">
                <FormLabel htmlFor="status" variant="auth" ontWeight="500" fontSize="sm">
                  Prioridade da campanha
                </FormLabel>
                <Slider
                  name="priority"
                  focusThumbOnChange={false}
                  value={formData.priority}
                  onChange={handleSlideChange}
                  defaultValue={5}
                  min={1}
                  max={10}
                  mt="-8px"
                  mb="15px"
                >
                  <SliderMark value={2} fontSize="sm" mt="5px">
                    Baixa
                  </SliderMark>
                  <SliderMark value={8} fontSize="sm" mt="5px">
                    Alta
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb fontSize="sm" boxSize="32px" children={formData.priority} />
                </Slider>
              </Flex>
            </FormControl>
          </Card>
        </GridItem>
        <GridItem colSpan={3}>
          {pipelineSteps && (
            <CampaignActivitiesForm
              formData={formData}
              setFormData={setFormData}
              accountName={accountName}
              pipelineSteps={pipelineSteps}
              // setPipelineList={setPipelineList}
            />
          )}
          <Flex justifyContent={'flex-end'}>
            <Button
              type="submit"
              fontSize="sm"
              background="#49BFFF"
              colorScheme="blue"
              fontWeight="800"
              w="120px"
              h="50"
              mt="20px"
              mb="24px"
              borderRadius="50px"
            >
              Salvar
            </Button>
          </Flex>
        </GridItem>
      </Grid>
      <Toast position="bottom-center" ref={toast} />
      <SimpleGrid columns={2} gap={5}>
        {/* {campaign_id && <NewCampaignScript campaign_id={campaign_id} campaignName={formData.subject} />} */}
      </SimpleGrid>
    </form>
  )
}
