import { Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

export function DailyPills() {
  const dailyPills = [
    {
      pill: 'Eu sei que consigo. Eu sei que consigo.',
      author: 'Watty Piper',
      origin: 'The little engine that could, 1930 - A bíblia das vendas'
    },
    {
      pill: 'PERGUNTE. Perguntas são o coração das vendas. Perguntas convertem o processo de vendas em um processo de compras. Elas revelam fatos e motivos para compras.',
      author: 'Jeffrey Gitomer',
      origin: 'A bíblia das vendas'
    },
    {
      pill: 'Faça uma previsão das objeções. Ensaie as respostas para enfrentá-las.',
      author: 'Jeffrey Gitomer',
      origin: 'A bíblia das vendas'
    }
  ]

  function luckyPill(pills) {
    const indiceAleatorio = Math.floor(Math.random() * pills.length)
    return pills[indiceAleatorio]
  }

  const pill = luckyPill(dailyPills)

  return (
    <Flex padding={'20px'} bg="white" borderRadius={'20px'} flexDirection={'column'} height={'fit-content'}>
      <Text fontSize={'18px'} fontWeight={'700'}>
        {pill.pill}
      </Text>
      <Text textAlign={'end'} fontWeight={'800'} fontStyle={'italic'}>
        {pill.author}
      </Text>
      <Text textAlign={'end'} fontStyle={'italic'}>
        {pill.origin}
      </Text>
    </Flex>
  )
}
