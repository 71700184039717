import { Flex } from '@chakra-ui/react'
import ProductsAndServicesTable from './components/productsAndServicesTable'

export default function ProductsAndServices() {
  return (
    <Flex>
      <ProductsAndServicesTable />
      <Flex></Flex>
    </Flex>
  )
}
